import React from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';

import Reminders from './Reminders/List';
import Notes from './Notes';
import Actions from './Actions';
import CarePlanPDFView from './CarePlanPDFView';

const CarePlan = ({ patient }) => {
  const componentRef = React.useRef();
  const [notes, setNotes] = React.useState([]);
  const [actions, setActions] = React.useState([]);

  return (
    <>
      <div className="pt4 pb0" style={{ margin: 'auto' }}>
        <ReactToPrint
          trigger={() => (
            <button
              type="button"
              className="btn btn-small white black-text ml2 right"
            >
              Export to PDF
            </button>
          )}
          content={() => componentRef.current}
          documentTitle={`${patient.patient.first_name} ${patient.patient.last_name || ''} - Care Plan`}
        />
      </div>
      <Notes patientId={patient.patient.id} callbackNotes={setNotes} />

      <div className="pt4 pb4" />

      <Actions patientId={patient.patient.id} callbackActions={setActions} />

      <div className="pt4 pb4" />

      <Reminders patientId={patient.patient.id} />

      <div style={{ display: 'none' }}>
        <div ref={componentRef}>
          <CarePlanPDFView patient={patient} notes={notes} actions={actions} />
        </div>
      </div>
    </>
  );
};

CarePlan.propTypes = {
  patient: PropTypes.shape({
    patient: PropTypes.shape({
      id: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CarePlan;
