import React from 'react';

import M from '@materializecss/materialize';
import { useHistory, useParams } from 'react-router-dom';
import { reducer, round, sendRequest } from '../../../utils';
import Loader from '../../../Components/Loader';
import { ENTRY_CODE_TO_TYPE_MAPPING } from '../../Data/constants';
import { ENTRY_TYPES_TO_FIELDS, INTEGER_ENTRY_TYPES } from './constants';

const EditAlertZone = () => {
  const { patientId, groupId } = useParams();
  const history = useHistory();

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isError: false, isLoading: false,
    },
  );

  const [type, setType] = React.useState(1);

  const fetchAlertZones = async () => {
    await sendRequest(`connections/patients/${patientId}/alert_zones`, 'GET')
      .then((response) => {
        if (response.status === 'success') {
          // Filter by alert zone group
          const alertZones = response.data.filter((az) => az.field_group === groupId);
          setType(alertZones[0].entry_type);

          dispatch({ type: 'FETCH_SUCCESS', payload: alertZones });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    const opts = Object.fromEntries(new FormData(e.target));

    if (type === 1) {
      // Send data in two requests: systolic and diastolic
      const systolicAlertZoneId = opts['id-0'];
      const systolicOpts = {
        entry_type: type,
        field_group: groupId,
        field_name: 'Systolic',
        high_value: opts['high_value-0'],
        low_value: opts['low_value-0'],
        value_unit: opts.value_unit,
        comparison_operator: 'in',
      };

      const diastolicAlertZoneId = opts['id-1'];
      const diastolicOpts = {
        entry_type: type,
        field_group: groupId,
        field_name: 'Diastolic',
        high_value: opts['high_value-1'],
        low_value: opts['low_value-1'],
        value_unit: opts.value_unit,
        comparison_operator: 'in',
      };

      Promise.all([
        sendRequest(`connections/patients/${patientId}/alert_zones/${systolicAlertZoneId}/`, 'PUT', systolicOpts),
        sendRequest(`connections/patients/${patientId}/alert_zones/${diastolicAlertZoneId}/`, 'PUT', diastolicOpts),
      ])
        .then(async ([r1, r2]) => {
          if (r1.status === 'success' && r2.status === 'success') {
            if (e.nativeEvent.submitter.value === 'submit-and-go-back') {
              history.goBack();
            }
            await fetchAlertZones();
            dispatch({ type: 'FETCH_SUCCESS', message: 'Alert zone updated successfully' });
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: r1.message || r2.message });
          }
        })
        .catch(() => dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' }));
    } else if (type === 2) {
      // Send data in two requests: pulse and SpO2
      const pulseAlertZoneId = opts['id-0'];
      const pulseOpts = {
        field_name: 'Pulse',
        field_group: groupId,
        high_value: opts['high_value-0'],
        low_value: opts['low_value-0'],
        value_unit: opts.value_unit,
        comparison_operator: 'in',
      };

      const spo2AlertZoneId = opts['id-1'];
      const spo2Opts = {
        field_name: '%SpO2',
        field_group: groupId,
        high_value: opts['high_value-1'],
        low_value: opts['low_value-1'],
        value_unit: opts.value_unit,
        comparison_operator: 'in',
      };

      Promise.all([
        sendRequest(`connections/patients/${patientId}/alert_zone/${pulseAlertZoneId}`, 'PUT', pulseOpts),
        sendRequest(`connections/patients/${patientId}/alert_zones/${spo2AlertZoneId}`, 'PUT', spo2Opts),
      ])
        .then(async ([r1, r2]) => {
          if (r1.status === 'success' && r2.status === 'success') {
            if (e.nativeEvent.submitter.value === 'submit-and-go-back') {
              history.goBack();
            }
            await fetchAlertZones();
            dispatch({ type: 'FETCH_SUCCESS', message: 'Alert zone updated successfully' });
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: r1.message || r2.message });
          }
        })
        .catch(() => dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' }));
    } else {
      const alertZoneId = opts.id;

      // Add missed fields
      opts.entry_type = type;
      opts.field_group = groupId;
      opts.field_name = ENTRY_TYPES_TO_FIELDS[type];
      opts.comparison_operator = 'in';

      sendRequest(`connections/patients/${patientId}/alert_zones/${alertZoneId}`, 'PUT', opts)
        .then(async (response) => {
          if (response.status === 'success') {
            if (e.nativeEvent.submitter.value === 'submit-and-go-back') {
              history.goBack();
            }
            await fetchAlertZones();
            dispatch({ type: 'FETCH_SUCCESS', message: 'Alert zone updated successfully' });
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: response.message });
          }
        })
        .catch(() => dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' }));
    }
  };

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    fetchAlertZones();
  }, []);

  React.useEffect(() => {
    M.AutoInit();
    M.updateTextFields();
  });

  // The two function below needed to disable decimals for specific types of alert zones
  // For now, it will remove decimals for BPM and Pulse
  const onKeyDown = (event) => {
    if (INTEGER_ENTRY_TYPES.includes(type) && ['.', ','].includes(event.key)) {
      event.preventDefault();
    }
  };

  const onInput = (event) => {
    if (INTEGER_ENTRY_TYPES.includes(type)) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = event.target.value.replace(/[^0-9]*/g, '');
    }
  };

  const getAlertZoneValue = (name, value) => {
    for (let i = 0; i < state.data.length; i += 1) {
      if (state.data[i].field_name === name) {
        return state.data[i][value];
      }
    }
    return null;
  };

  const getDefaultValue = (name, value) => {
    if (INTEGER_ENTRY_TYPES.includes(type)) {
      return round(getAlertZoneValue(name, value));
    }
    return getAlertZoneValue(name, value);
  };

  return (
    <div className="container mt4 mb4">
      <h5 className="header mt4">
        Edit Alert Zone
      </h5>

      {
        state.isLoading
          ? <Loader />
          : (
            <div className="row">
              <form className="col s12" onSubmit={handleSubmit}>
                <div className={`form-messages ${state.message ? '' : 'hide'}`}>{state.message}</div>
                <div className={`form-errors ${state.isError ? '' : 'hide'}`}>{state.error}</div>

                <div className="row">
                  <div className="input-field col s12 m6">
                    <label htmlFor="entry_type">Type</label>
                    <input
                      id="entry_type"
                      name="entry_type"
                      type="text"
                      readOnly
                      defaultValue={ENTRY_CODE_TO_TYPE_MAPPING[type]}
                    />
                  </div>

                  <div className="input-field col s12 m6">
                    <label htmlFor="value_unit">Units</label>
                    <input
                      id="value_unit"
                      name="value_unit"
                      type="text"
                      defaultValue={
                        state.data
                          .map((az) => az.value_unit)
                          .reduce(
                            (acc, curr) => (acc.includes(curr) ? acc : [...acc, curr]),
                            [],
                          ).join(', ')
                      }
                      readOnly
                    />
                  </div>
                </div>

                {
                  type === 1 && (
                  <>
                    <h6>Systolic</h6>
                    <div className="row">
                      <div className="input-field col s12 m6">
                        <label htmlFor="high_value-0">Max. value</label>
                        <input
                          id="high_value-0"
                          name="high_value-0"
                          type="number"
                          className="validate"
                          step={INTEGER_ENTRY_TYPES.includes(type) ? 1 : 0.1}
                          defaultValue={getDefaultValue('Systolic', 'high_value')}
                          onKeyDown={onKeyDown}
                          onInput={onInput}
                          required
                        />
                      </div>

                      <div className="input-field col s12 m6">
                        <label htmlFor="low_value-0">Min. value</label>
                        <input
                          id="low_value-0"
                          name="low_value-0"
                          type="number"
                          className="validate"
                          step={INTEGER_ENTRY_TYPES.includes(type) ? 1 : 0.1}
                          defaultValue={getDefaultValue('Systolic', 'low_value')}
                          onKeyDown={onKeyDown}
                          onInput={onInput}
                          required
                        />
                      </div>
                    </div>

                    <h6>Diastolic</h6>
                    <div className="row">
                      <div className="input-field col s12 m6">
                        <label htmlFor="high_value-1">Max. value</label>
                        <input
                          id="high_value-1"
                          name="high_value-1"
                          type="number"
                          className="validate"
                          step={INTEGER_ENTRY_TYPES.includes(type) ? 1 : 0.1}
                          defaultValue={getDefaultValue('Diastolic', 'high_value')}
                          onKeyDown={onKeyDown}
                          onInput={onInput}
                          required
                        />
                      </div>

                      <div className="input-field col s12 m6">
                        <label htmlFor="low_value-1">Min. value</label>
                        <input
                          id="low_value-1"
                          name="low_value-1"
                          type="number"
                          className="validate"
                          step={INTEGER_ENTRY_TYPES.includes(type) ? 1 : 0.1}
                          defaultValue={getDefaultValue('Diastolic', 'low_value')}
                          onKeyDown={onKeyDown}
                          onInput={onInput}
                          required
                        />
                      </div>
                    </div>

                    <input type="hidden" name="id-0" defaultValue={getAlertZoneValue('Systolic', 'id')} />
                    <input type="hidden" name="id-1" defaultValue={getAlertZoneValue('Diastolic', 'id')} />
                  </>
                  )
                }

                {
                  type === 2 && (
                  <>
                    <h6>Pulse</h6>
                    <div className="row">
                      <div className="input-field col s12 m6">
                        <label htmlFor="high_value-0">Max. value</label>
                        <input
                          id="high_value-0"
                          name="high_value-0"
                          type="number"
                          className="validate"
                          step={INTEGER_ENTRY_TYPES.includes(type) ? 1 : 0.1}
                          defaultValue={getDefaultValue('Pulse', 'high_value')}
                          onKeyDown={onKeyDown}
                          onInput={onInput}
                          required
                        />
                      </div>

                      <div className="input-field col s12 m6">
                        <label htmlFor="low_value-0">Min. value</label>
                        <input
                          id="low_value-0"
                          name="low_value-0"
                          type="number"
                          className="validate"
                          step={INTEGER_ENTRY_TYPES.includes(type) ? 1 : 0.1}
                          defaultValue={getDefaultValue('Pulse', 'low_value')}
                          onKeyDown={onKeyDown}
                          onInput={onInput}
                          required
                        />
                      </div>
                    </div>

                    <h6>SpO2</h6>
                    <div className="row">
                      <div className="input-field col s12 m6">
                        <label htmlFor="high_value-1">Max. value</label>
                        <input
                          id="high_value-1"
                          name="high_value-1"
                          type="number"
                          className="validate"
                          step={INTEGER_ENTRY_TYPES.includes(type) ? 1 : 0.1}
                          defaultValue={getDefaultValue('Spo2', 'high_value')}
                          onKeyDown={onKeyDown}
                          onInput={onInput}
                          required
                        />
                      </div>

                      <div className="input-field col s12 m6">
                        <label htmlFor="low_value-1">Min. value</label>
                        <input
                          id="low_value-1"
                          name="low_value-1"
                          type="number"
                          className="validate"
                          step={INTEGER_ENTRY_TYPES.includes(type) ? 1 : 0.1}
                          defaultValue={getDefaultValue('Spo2', 'low_value')}
                          onKeyDown={onKeyDown}
                          onInput={onInput}
                          required
                        />
                      </div>
                    </div>

                    <input type="hidden" name="id-0" defaultValue={getAlertZoneValue('Systolic', 'id')} />
                    <input type="hidden" name="id-1" defaultValue={getAlertZoneValue('Diastolic', 'id')} />
                  </>
                  )
                }

                {
                  type !== 1 && type !== 2 && (
                    <>
                      <div className="row">
                        <div className="input-field col s12 m6">
                          <label htmlFor="high_value">Max. value</label>
                          <input
                            id="high_value"
                            name="high_value"
                            type="number"
                            className="validate"
                            step={INTEGER_ENTRY_TYPES.includes(type) ? 1 : 0.1}
                            defaultValue={getDefaultValue(ENTRY_TYPES_TO_FIELDS[type], 'high_value')}
                            onKeyDown={onKeyDown}
                            onInput={onInput}
                            required
                          />
                        </div>

                        <div className="input-field col s12 m6">
                          <label htmlFor="low_value">Min. value</label>
                          <input
                            id="low_value"
                            name="low_value"
                            type="number"
                            className="validate"
                            step={INTEGER_ENTRY_TYPES.includes(type) ? 1 : 0.1}
                            defaultValue={getDefaultValue(ENTRY_TYPES_TO_FIELDS[type], 'low_value')}
                            onKeyDown={onKeyDown}
                            onInput={onInput}
                            required
                          />
                        </div>
                      </div>
                      <input type="hidden" name="id" defaultValue={state.data[0]?.id} />
                    </>
                  )
                }

                <button type="submit" value="submit" className="btn black mt2">Save</button>
                <button type="submit" value="submit-and-go-back" className="btn black mt2 ml2">Save And Close</button>
                <button
                  type="button"
                  value="close"
                  className="btn white black-text mt2 ml2"
                  onClick={() => history.goBack()}
                >
                  Close
                </button>
              </form>
            </div>
          )
      }
    </div>
  );
};

EditAlertZone.propTypes = {};

export default EditAlertZone;
