const ENTRY_TYPES_TO_UNITS = {
  1: 'mmHg',
  2: 'bpm / %SpO2',
  3: 'lbs',
  4: 'mg/dL',
  5: 'bpm',
  12: '°F',
};

const ENTRY_TYPES_TO_FIELDS = {
  3: 'Weight',
  4: 'Glucose',
  5: 'Pulse',
  12: 'Temperature',
};

const INTEGER_ENTRY_TYPES = [1, 2, 4];

export {
  ENTRY_TYPES_TO_UNITS,
  ENTRY_TYPES_TO_FIELDS,
  INTEGER_ENTRY_TYPES,
};
