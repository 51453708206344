import React from 'react';
import ChangePasswordForm from './ChangePasswordForm';
import GeneralSettingsForm from './GeneralSettingsForm';

const MySettings = () => (
  <div className="container mt4 mb4">
    <h4 className="mb4">My Settings</h4>

    <GeneralSettingsForm />

    <div className="divider mt4 mb4" />

    <ChangePasswordForm />
  </div>
);

export default MySettings;
