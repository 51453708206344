import M from '@materializecss/materialize';
import moment from 'moment';
import React from 'react';
import { useHistory, useParams } from 'react-router';

import Loader from '../Components/Loader';
import { reducer, sendRequest } from '../utils';
import Select from '../Components/Select';
import DecimalInput from '../Components/DecimalInput';

const CHRONIC_CONDITIONS = [
  ['Asthma', 'Asthma'],
  ['Hypertension', 'Hypertension'],
  ['Obesity', 'Obesity'],
  ['Diabetes', 'Diabetes'],
  ['Congestive Heart Failure (CHF)', 'Congestive Heart Failure (CHF)'],
  ['Coronary Artery Disease (CAD)', 'Coronary Artery Disease (CAD)'],
  ['Atrial Fibrillation (AFib)', 'Atrial Fibrillation (AFib)'],
  ['Chronic Kidney Disease (CKD)', 'Chronic Kidney Disease (CKD)'],
  ['Stroke', 'Stroke'],
  ['Hypothyroidism', 'Hypothyroidism'],
  ['Hyperthyroidism', 'Hyperthyroidism'],
  ['Sleep Apnea', 'Sleep Apnea'],
  ['Hypoglycemia', 'Hypoglycemia'],
  ['Chronic Pain Management', 'Chronic Pain Management'],
  ['Postoperative Care', 'Postoperative Care'],
  ['Depression and Anxiety', 'Depression and Anxiety'],
  ["Alzheimer's Disease and Dementia", "Alzheimer's Disease and Dementia"],
  ['Rheumatoid Arthritis', 'Rheumatoid Arthritis'],
  ['Osteoarthritis', 'Osteoarthritis'],
  ['Chronic Migraines', 'Chronic Migraines'],
  ['Chronic Wound Management', 'Chronic Wound Management'],
  ['Preeclampsia', 'Preeclampsia'],
  ['Postpartum preeclampsia', 'Postpartum preeclampsia'],
  ['Infectious Diseases', 'Infectious Diseases'],
  ['Gastrointestinal Disorders', 'Gastrointestinal Disorders'],
  ['Autoimmune Disorders', 'Autoimmune Disorders'],
  ['Substance Use Disorder Management', 'Substance Use Disorder Management'],
  ['Geriatrics', 'Geriatrics'],
  ['Post-transplant Care', 'Post-transplant Care'],
  ['Cardiac Arrhythmias', 'Cardiac Arrhythmias'],
  ['Peripheral Artery Disease (PAD)', 'Peripheral Artery Disease (PAD)'],
  ['Cystic Fibrosis', 'Cystic Fibrosis'],
  ['Sickle Cell Disease', 'Sickle Cell Disease'],
  ['Organ Transplant Recipients', 'Organ Transplant Recipients'],
  ['Palliative Care', 'Palliative Care']];

const PatientInfoEdit = () => {
  const { patientId } = useParams();
  const history = useHistory();

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {},
      message: '',
      error: '',
      isLoading: true,
      isError: false,
    },
  );
  const [heightInFt, setHeightInFt] = React.useState(null);
  const [weightInLbs, setWeightInLbs] = React.useState(null);
  const [needsAttention, setNeedsAttention] = React.useState(false);
  const [chronicConditions, setChronicConditions] = React.useState([]);

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest(`connections/patients/${patientId}`, 'GET')
      .then((response) => {
        const { patient } = response.data;
        dispatch({
          type: 'FETCH_SUCCESS',
          payload: {
            first_name: patient.first_name,
            last_name: patient.last_name,
            email: patient.email,
            patient_profile: {
              phone_number: patient.patient_profile.phone_number,
              gender: patient.patient_profile.gender,
              date_of_birth: patient.patient_profile.date_of_birth,
              height_in_ft: patient.patient_profile.height_in_ft,
              weight_in_lbs: patient.patient_profile.weight_in_lbs,
              injuries: patient.patient_profile.injuries || [],
              medications: patient.patient_profile.medications || [],
              conditions: patient.patient_profile.conditions || [],
              surgeries_treatments: patient.patient_profile.surgeries_treatments || [],
              emergency_contact_name: patient.patient_profile.emergency_contact_name,
              emergency_contact_email: patient.patient_profile.emergency_contact_email,
              emergency_contact_phone_number: patient.patient_profile.emergency_contact_phone_number,
              emergency_contact_relationship: patient.patient_profile.emergency_contact_relationship,
            },
          },
        });

        setHeightInFt(patient.patient_profile.height_in_ft);
        setWeightInLbs(patient.patient_profile.weight_in_lbs);
        setNeedsAttention(patient.patient_profile.needs_attention);
        setChronicConditions(patient.patient_profile.chronic_conditions || []);

        // Initialize form fields
        M.AutoInit();
        M.updateTextFields();
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  }, [patientId]);

  React.useEffect(() => {
    M.updateTextFields();

    // Resize <textarea> elements
    setTimeout(
      () => {
        document.querySelectorAll('textarea').forEach(
          (textarea) => M.textareaAutoResize(textarea),
        );
      }, 250,
    );
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    const opts = Object.fromEntries(new FormData(e.target));

    // Weight and height
    opts.weight_in_lbs = parseFloat(weightInLbs);
    opts.height_in_ft = parseFloat(heightInFt);

    // Split by newlins medications, injuries, surgeries_treatments, conditions
    ['medications', 'injuries', 'surgeries_treatments', 'conditions'].forEach((field) => {
      opts[field] = opts[field].split('\n');
    });

    // Needs Attention
    opts.needs_attention = needsAttention;

    // Chronic Conditions
    opts.chronic_conditions = chronicConditions;

    sendRequest(`connections/patients/${patientId}`, 'PUT', opts)
      .then((response) => {
        if (response.status === 'error') {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        } else {
          history.goBack();
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE' });
      });
  };

  const handleChronicConditionsChange = (e) => {
    // This is multiple select, so we need to convert it to array
    const selectedOptions = Array.from(e.target.options).filter((option) => option.selected);
    const selectedValues = selectedOptions.map((option) => option.value);

    setChronicConditions(selectedValues);
  };

  if (state.isLoading) {
    return <Loader />;
  }

  return (
    <div className="container mt4 mb4">
      <div className="mt4">
        <h5 className="header mb4">
          Edit Patient Info
        </h5>
        {
          state.isLoading
            ? <Loader />
            : (
              <div className="row">
                <form className="col s12" onSubmit={handleSubmit}>
                  <div className={`form-messages ${state.message ? '' : 'hide'}`}>{state.message}</div>
                  <div className={`form-errors ${state.isError ? '' : 'hide'}`}>{state.error}</div>

                  <div className="row">
                    <div className="input-field col s12 m6">
                      <label htmlFor="first_name">First Name</label>
                      <input
                        id="first_name"
                        name="first_name"
                        type="text"
                        className="validate"
                        required
                        defaultValue={state.data.first_name}
                        disabled
                      />
                    </div>
                    <div className="input-field col s12 m6">
                      <label htmlFor="last_name">Last Name</label>
                      <input
                        id="last_name"
                        name="last_name"
                        type="text"
                        className="validate"
                        required
                        defaultValue={state.data.last_name || ''}
                        disabled
                      />
                    </div>
                    <div className="input-field col s12 m6">
                      <label htmlFor="phone_number">Phone Number</label>
                      <input
                        id="phone_number"
                        name="phone_number"
                        type="tel"
                        defaultValue={state.data.patient_profile?.phone_number}
                        disabled
                      />
                    </div>
                    <div className="input-field col s12 m6">
                      <label htmlFor="email">Email</label>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        defaultValue={state.data.email}
                        // disabled
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="input-field col s12">
                      <select name="gender">
                        <option defaultValue={state.data.patient_profile?.gender} disabled>
                          Choose gender
                        </option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                      </select>
                      <label htmlFor="gender">Gender</label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="input-field col s12">
                      <input
                        id="date_of_birth"
                        name="date_of_birth"
                        type="date"
                        className="validate"
                        defaultValue={moment(state.data.patient_profile?.date_of_birth, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                      />
                      <label htmlFor="password">Date Of Birth</label>
                    </div>
                  </div>

                  <div className="row">
                    <DecimalInput
                      name="height_in_ft"
                      label="Height, ft"
                      defaultValue={heightInFt}
                      onValueChange={setHeightInFt}
                    />
                    <DecimalInput
                      name="weight_in_lbs"
                      label="Weight, lbs"
                      defaultValue={weightInLbs}
                      onValueChange={setWeightInLbs}
                    />
                  </div>

                  <div className="row">
                    <div className="input-field col s12">
                      <Select
                        name="chronic_conditions"
                        label="Chronic Conditions"
                        values={CHRONIC_CONDITIONS}
                        defaultValue={chronicConditions}
                        onChange={handleChronicConditionsChange}
                        multiple
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="input-field col s12">
                      <div className="left mr4">
                        <label className="font-1" htmlFor="needs_attention">
                          Needs Attention
                          <input type="hidden" />
                        </label>
                      </div>
                      <div className="switch high-risk-switch left">
                        <label htmlFor="needs_attention">
                          No
                          <input
                            id="needs_attention"
                            name="needs_attention"
                            type="checkbox"
                            checked={needsAttention}
                            onChange={() => setNeedsAttention(!needsAttention)}
                          />
                          <span className="lever" />
                          Yes
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="input-field col s12">
                      <textarea
                        id="injuries"
                        name="injuries"
                        className="materialize-textarea validate"
                        defaultValue={state.data.patient_profile.injuries.join('\n')}
                      />
                      <label htmlFor="injuries">Injuries</label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="input-field col s12">
                      <textarea
                        id="medications"
                        name="medications"
                        className="materialize-textarea validate"
                        defaultValue={state.data.patient_profile.medications.join('\n')}
                      />
                      <label htmlFor="medications">Medications</label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="input-field col s12">
                      <textarea
                        id="surgeries_treatments"
                        name="surgeries_treatments"
                        className="materialize-textarea validate"
                        defaultValue={state.data.patient_profile.surgeries_treatments.join('\n')}
                      />
                      <label htmlFor="surgeries_treatments">Surgeries / Treatments</label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="input-field col s12">
                      <textarea
                        id="conditions"
                        name="conditions"
                        className="materialize-textarea validate"
                        defaultValue={state.data.patient_profile.conditions.join('\n')}
                      />
                      <label htmlFor="conditions">Conditions</label>
                    </div>
                  </div>

                  <div className="row">
                    <h6>Emergency Contact</h6>

                    <div className="input-field col s12 m6">
                      <input
                        id="emergency_contact_name"
                        name="emergency_contact_name"
                        type="text"
                        className="validate"
                        defaultValue={state.data.patient_profile.emergency_contact_name}
                      />
                      <label htmlFor="contact_name">Name</label>
                    </div>

                    <div className="input-field col s12 m6">
                      <input
                        id="emergency_contact_phone_number"
                        name="emergency_contact_phone_number"
                        type="tel"
                        className="validate"
                        defaultValue={state.data.patient_profile.emergency_contact_phone_number}
                      />
                      <label htmlFor="contact_phone_number">Phone</label>
                    </div>

                    <div className="input-field col s12 m6">
                      <input
                        id="emergency_contact_email"
                        name="emergency_contact_email"
                        type="email"
                        className="validate"
                        defaultValue={state.data.patient_profile.emergency_contact_email}
                      />
                      <label htmlFor="contact_email">Email</label>
                    </div>

                    <div className="input-field col s12 m6">
                      <input
                        id="emergency_contact_relationship"
                        name="emergency_contact_relationship"
                        type="text"
                        className="validate"
                        defaultValue={state.data.patient_profile.emergency_contact_relationship}
                      />
                      <label htmlFor="contact_relation">Relation</label>
                    </div>

                  </div>

                  <button type="submit" className="btn black mt2 ml2">Save</button>
                  <button
                    type="button"
                    value="close"
                    className="btn white black-text mt2 ml2"
                    onClick={() => history.goBack()}
                  >
                    Close
                  </button>
                </form>
              </div>
            )
        }
      </div>
    </div>
  );
};

export default PatientInfoEdit;
