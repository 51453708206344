import React from 'react';
import PropTypes from 'prop-types';
import M from '@materializecss/materialize';

const TimeSelector = ({ defaultTimes, onChange }) => {
  const [times, setTimes] = React.useState([]);
  const timepickerRef = React.useRef(null);
  const timepickerInstance = React.useRef(null);

  // Function to add selected time to the list
  const handleAddTime = React.useCallback(() => {
    const timeValue = timepickerRef.current.value;
    if (!timeValue || times.includes(timeValue)) {
      return;
    }

    const newTimes = [...times, timeValue].sort((a, b) => {
      const aTime = new Date(`01/01/2007 ${a}`);
      const bTime = new Date(`01/01/2007 ${b}`);
      return aTime - bTime;
    });

    setTimes(newTimes);
    onChange(newTimes);

    // Clear the timepicker input after adding the time
    timepickerRef.current.value = '';
  }, [times, onChange]);

  React.useEffect(() => {
    const initTimepicker = () => {
      if (timepickerRef.current) {
        // Destroy previous instance if it exists
        if (timepickerInstance.current) {
          timepickerInstance.current.destroy();
        }

        // Initialize timepicker instance
        timepickerInstance.current = M.Timepicker.init(timepickerRef.current, {
          twelveHour: true,
        });
      }
    };

    initTimepicker(); // Initialize when component mounts

    return () => {
      if (timepickerInstance.current) {
        timepickerInstance.current.destroy();
        timepickerInstance.current = null;
      }
    };
  }, []);

  React.useEffect(() => {
    setTimes(defaultTimes);
  }, [defaultTimes]);

  const handleRemoveTime = (e) => {
    e.preventDefault();
    const time = e.target.previousSibling.textContent;
    setTimes((prevTimes) => prevTimes.filter((t) => t !== time));
  };

  return (
    <>
      <div className={`row ${times.length === 0 ? 'd-none' : ''}`}>
        {times.map((time) => (
          <span className="badge black-text border left time-badge" key={time}>
            {time}
            <a href="/#" className="ml1 black-text" onClick={handleRemoveTime}>
              X
            </a>
          </span>
        ))}
      </div>

      <div className="row d-flex align-items-center">
        <div className="input-field col s10 m10">
          <input
            type="text"
            id="reminder_time"
            name="reminder_time"
            className="timepicker"
            ref={timepickerRef}
          />
          <label htmlFor="reminder_time">Time</label>
        </div>

        <div className="col s2">
          <button
            className="btn black text-white w-100"
            type="button"
            onClick={handleAddTime}
          >
            Save Time
          </button>
        </div>
      </div>
    </>
  );
};

TimeSelector.propTypes = {
  defaultTimes: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};

TimeSelector.defaultProps = {
  defaultTimes: [],
};

export default TimeSelector;
