import M from '@materializecss/materialize';
import React from 'react';

import { Redirect } from 'react-router-dom';

import { sendRequest, useAuth } from '../utils';

function ResetPassword() {
  const [logged] = useAuth();

  const [state, setState] = React.useState('initial'); // 'initial', 'code', 'change', 'success'

  const [email, setEmail] = React.useState('');
  const [code, setCode] = React.useState('');
  const [token, setToken] = React.useState('');

  const [newPassword, setNewPassword] = React.useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = React.useState('');

  const [message, setMessage] = React.useState('');
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    M.updateTextFields();
  });

  React.useEffect(() => {
    if (newPassword !== newPasswordConfirmation) {
      setError('Passwords do not match');
    } else {
      setError('');
    }
  }, [newPassword, newPasswordConfirmation]);

  if (logged) {
    return <Redirect to="/" />;
  }

  const onEmailConfirmClick = (e) => {
    e.preventDefault();

    sendRequest('users/reset_password', 'POST', { email }, false)
      .then((response) => {
        if (response.status === 'success') {
          setMessage('Check your email for the reset code');
          setState('code');
        } else {
          setError(response.message);
        }
      });
  };

  const onCodeConfirmClick = (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    sendRequest('users/reset_password/verify', 'PUT', { email, code }, false)
      .then((response) => {
        if (response.status === 'success') {
          setToken(response.data.token);
          setState('change');
        } else {
          setError(response.message);
        }
      });
  };

  const onSavePasswordClick = (e) => {
    e.preventDefault();

    const opts = {
      email,
      token,
      password: newPassword,
    };

    sendRequest('users/reset_password/set_password', 'PUT', opts, false)
      .then((response) => {
        if (response.status === 'success') {
          setState('success');
          setMessage('Password changed successfully');
        } else {
          setError(response.message);
        }
      })
      .catch(() => setError('Something went wrong'));
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div className="container mt4 mb4">
      <h4 className="mb4">Reset Password</h4>
      <div className="row">
        <form className="col s12">
          <div className={`form-messages ${message ? '' : 'hide'}`}>{message}</div>
          <div className={`form-errors ${error ? '' : 'hide'}`}>{error}</div>

          {
            state === 'initial' && (
              <>
                <div className="row">
                  <div className="input-field col s12">
                    <input
                      id="email"
                      type="email"
                      className="validate"
                      onChange={handleEmailChange}
                      value={email}
                    />
                    <label htmlFor="email">Email</label>
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn black mt2"
                  onClick={onEmailConfirmClick}
                >
                  Reset Password
                </button>
              </>
            )
          }

          {
            state === 'code' && (
              <>
                <div className="row">
                  <div className="input-field col s12">
                    <input
                      id="email"
                      type="email"
                      value={email}
                      readOnly
                    />
                    <label htmlFor="email">Email</label>
                  </div>
                </div>

                <div className="row">
                  <div className="input-field col s12">
                    <input
                      id="code"
                      type="text"
                      className="validate"
                      onChange={(e) => setCode(e.target.value)}
                      value={code}
                    />
                    <label htmlFor="code">Code</label>
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn black mt2"
                  onClick={onCodeConfirmClick}
                >
                  Verify Code
                </button>
              </>
            )
          }

          {
            state === 'change' && (
              <>
                <div className="row">
                  <div className="input-field col s12 m6">
                    <input
                      id="new_password"
                      name="new_password"
                      type="password"
                      className="validate"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                    />
                    <label htmlFor="new_password">New Password</label>
                  </div>

                  <div className="input-field col s12 m6">
                    <input
                      id="new_password_confirmation"
                      name="new_password_confirmation"
                      type="password"
                      className="validate"
                      value={newPasswordConfirmation}
                      onChange={(e) => setNewPasswordConfirmation(e.target.value)}
                      required
                    />
                    <label htmlFor="new_password_confirmation">New Password Confirmation</label>
                    <span className="helper-text" data-error={error} />
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn black"
                  onClick={onSavePasswordClick}
                  disabled={newPassword !== newPasswordConfirmation}
                >
                  Save Password
                </button>
              </>
            )
          }

        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
