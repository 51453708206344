import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import {
  groupBy, reducer, round, sendRequest,
} from '../../../utils';
import Loader from '../../../Components/Loader';

const TYPES = {
  1: 'Blood Pressure',
  2: 'Pulse Oximeter',
  3: 'Weight',
  4: 'Blood Glucose',
  5: 'ECG',
  6: 'Medication',
  7: 'Steps',
  8: 'Calories',
  9: 'Water',
  10: 'Pain Level',
  11: 'Sleep',
  12: 'Body Temperature',
};

const FIELD_LABELS = {
  Weight: 'Weight',
  BloodOxygenSaturation: 'Blood Oxygen Saturation',
  Spo2: 'SpO2',
  Pulse: 'Pulse',
  Systolic: 'Systolic',
  Diastolic: 'Diastolic',
  Temperature: 'Temperature',
  BloodGlucose: 'Blood Glucose',
};

const INTEGER_TYPES = ['BPM', 'Pulse', 'PulseOximeter', 'BGM'];

const AlertZones = ({ patientId }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isError: false, isLoading: false,
    },
  );

  const handleDeleteAlertZone = (fieldGroup) => {
    dispatch({
      type: 'FETCH_SUCCESS', payload: state.data.filter((az) => az.field_group !== fieldGroup),
    });
  };

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest(`connections/patients/${patientId}/alert_zones`, 'GET')
      .then((response) => {
        if (response.status === 'success') {
          dispatch({ type: 'FETCH_SUCCESS', payload: response.data });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  }, []);

  if (state.isLoading) {
    return (
      <div>
        <h5 className="header mt4">
          Alert Zones
        </h5>
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <h5 className="header-with-button mt4 mb4">
        Alert Zones
        <Link to={`/patients/${patientId}/alertzones`} className="btn btn-small black right">
          <i className="material-icons left">add</i>
          Add
        </Link>
      </h5>

      {
        state.data.length
          ? (
            <>
              {
                Object.entries(groupBy(state.data, 'field_group')).map(
                  ([group, az]) => (
                    <AlertZone
                      key={group}
                      data={az}
                      patientId={patientId}
                      handleDeleteAlertZone={handleDeleteAlertZone}
                    />
                  ),
                )
              }
            </>
          )
          : <p>No alert zones found</p>
      }
    </div>
  );
};

AlertZones.propTypes = {
  patientId: PropTypes.string.isRequired,
};

const AlertZone = ({ data, patientId, handleDeleteAlertZone }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data, message: '', error: '', isLoading: false, isError: false,
    },
  );

  const onDeleteAlertzone = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    const promises = state.data.map(
      (az) => sendRequest(`connections/patients/${patientId}/alert_zones/${az.id}`, 'DELETE'),
    );

    Promise.all(promises)
      .then((results) => {
        const failedResults = results.filter((r) => r.status === 'error');
        if (failedResults.length) {
          dispatch({ type: 'FETCH_FAILURE', error: failedResults[0].message });
        } else {
          dispatch({ type: 'FETCH_SUCCESS', message: 'Alert zone was removed successfully' });
          handleDeleteAlertZone(state.data[0].field_group);
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  };

  if (state.isLoading) {
    return (
      <Loader />
    );
  }

  if (state.message) {
    return (
      <div className="form-messages">{state.message}</div>
    );
  }

  return (
    <div className="row flex alert-zone">
      <div className={`form-errors col s12 ${state.error ? '' : 'hide'}`}>{state.error}</div>
      <div className="col s12 m4 alert-zone-type">
        <b>{TYPES[data[0].entry_type]}</b>
      </div>

      {
        data.map(
          (az) => (
            <div className="col s12 m3 alert-zone-block" key={az.id}>
              <table>
                <thead>
                  <tr>
                    <th key={az.id} className="text-center" colSpan="2">{FIELD_LABELS[az.field_name]}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">
                      <span className="grey-text">MAX</span>
                      <h5 className="m0">
                        <b>
                          {
                          INTEGER_TYPES.includes(az.field_type)
                            ? round(az.high_value)
                            : az.high_value
                          }
                        </b>
                      </h5>
                      <small>{az.value_unit}</small>
                    </td>

                    <td className="text-center">
                      <span className="grey-text">MIN</span>
                      <h5 className="m0">
                        <b>
                          {
                          INTEGER_TYPES.includes(az.field_type)
                            ? round(az.low_value)
                            : az.low_value
                          }
                        </b>
                      </h5>
                      <small>{az.value_unit}</small>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ),
        )
      }

      {/* Add empty column in case of single measurement */}
      {
        data.length === 1
        && <div className="col s12 m3 alert-zone-block" />
      }

      <div className="col s12 m2">
        <Link
          to={`/patients/${patientId}/alertzones/${state.data[0].field_group}`}
          className="btn btn-small black mr2 mb2"
        >
          <i className="material-icons left">edit</i>
          Edit
        </Link>

        <button
          type="button"
          className="btn btn-small red white-text mb2"
          onClick={onDeleteAlertzone}
        >
          <i className="material-icons left">clear</i>
          Delete
        </button>
      </div>
    </div>
  );
};

AlertZone.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      entry_type: PropTypes.number.isRequired,
    }),
  ).isRequired,
  patientId: PropTypes.string.isRequired,
  handleDeleteAlertZone: PropTypes.func.isRequired,
};

export default AlertZones;
