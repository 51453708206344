import React from 'react';
import { Redirect } from 'react-router-dom';

import { login, useAuth, sendRequest, logout } from '../utils';

function Login() {
  const [logged] = useAuth();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');

  if (logged) {
    return <Redirect to="/" />;
  }

  const onSubmitClick = (e) => {
    e.preventDefault();
    const opts = {
      email,
      password,
    };

    sendRequest('login', 'POST', opts, false)
      .then((response) => {
        if (response.status === 'success') {
          login(response.data);

          // Logout user if not a doctor
          sendRequest('users/me', 'GET')
            .then((resp) => {
              if (!['Doctor', 'Colleague'].includes(resp.data.role)) {
                alert('This portal is only for doctors!');
                logout();
              }
            });
        } else {
          setError(response.message);
        }
      });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div className="container mt4 mb4">
      <h4 className="mb4">Login</h4>
      <div className="row">
        <form className="col s12">
          <div className={`form-errors ${error ? '' : 'hide'}`}>{error}</div>
          <div className="row">
            <div className="input-field col s12">
              {/* <input
                id="email"
                type="email"
                className="validate"
                onChange={handleEmailChange}
                value={email}
              />
              <label htmlFor="email">Email</label> */}

              <input
                id="email"
                type="email"
                className="validate"
                onChange={handleEmailChange}
                value={email}
              />
              <label htmlFor="email">Email</label>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s12">
              <input
                id="password"
                type="password"
                className="validate"
                onChange={handlePasswordChange}
                value={password}
              />
              <label htmlFor="password">Password</label>
            </div>
          </div>
          <button type="submit" className="btn black mt2 mr2 mb2" onClick={onSubmitClick}>Login</button>
          {/* <a href="/register" className="btn black mt2 mr2 mb2">Register</a> */}
          <a href="/restore-password" className="btn black mt2 mb2">Forgot Password</a>
        </form>
      </div>
    </div>
  );
}

export default Login;
