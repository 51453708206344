import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { reducer, sendRequest } from '../utils';

function updateTimeSpent(serverStartTime, timeDiff) {
  /*
  Returns time spent in milliseconds
  */
  const currentDatetime = moment.utc();
  const startDatetime = moment.utc(serverStartTime);

  const diff = currentDatetime.diff(startDatetime);
  const duration = moment.duration(diff).add(timeDiff);

  return duration.asMilliseconds();
}

function generateTimeString(milliseconds) {
  /*
  Returns a string in the format of 'HH:MM:SS'
  */
  const duration = moment.duration(milliseconds);

  const days = duration.days();
  const hours = (`0${days * 24 + duration.hours()}`).slice(-2);
  const minutes = (`0${duration.minutes()}`).slice(-2);
  const seconds = (`0${duration.seconds()}`).slice(-2);

  return `${hours}:${minutes}:${seconds}`;
}

function showTimerAlert(milliseconds) {
  // In some cases difference between start_time and current time can be less than 1 second
  // This is why we need to add a bit hacky additional check for 1000 milliseconds
  if (milliseconds < 1000) {
    return false;
  }

  // Show timer alert each 5 minutes
  return (Math.round(milliseconds / 1000) % (60 * 5)) === 0;
}

const Timer = ({ onUpdate }) => {
  const { patientId } = useParams();

  const [timeSpent, setTimeSpent] = React.useState(null);

  // This state used to avoid issues with wrong time on the client side
  const [timeDiff, setTimeDiff] = React.useState(moment.duration(0));

  const [state, dispatch] = React.useReducer(
    reducer,
    { data: {}, isLoading: true, isError: false },
  );

  const handleStartTimer = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    sendRequest(`connections/patients/${patientId}/time_trackers`, 'POST', {})
      .then((response) => {
        const timer = response.data;

        dispatch({
          type: 'FETCH_SUCCESS',
          payload: timer,
        });

        // Save time diff between server and client
        const serverTime = moment.utc(timer.server_time).milliseconds(0);
        const timeDiff = moment.duration(serverTime.diff(moment.utc().milliseconds(0)));
        setTimeDiff(timeDiff);

        // Update status
        onUpdate(true);
      })
      .catch(() => {
        alert('Something went wrong. Please try again.');
      });
  };

  const handleStopTimer = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    sendRequest(`connections/patients/${patientId}/time_trackers/${state.data.id}/end`, 'PATCH')
      .then((response) => {
        if (response.status === 'success') {
        // When timer stops, clean up the state
          setTimeSpent(null);

          dispatch({
            type: 'FETCH_SUCCESS',
            payload: {},
          });

          // Update status
          onUpdate(false);
        } else {
          alert('Something went wrong. Please try again.');
        }
      });
  };

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest(`connections/patients/${patientId}/time_trackers`, 'GET').then((response) => {
      const timer = response.data.time_trackers.filter((t) => !t.ended_at)[0];

      // Save time diff between server and client
      const serverTime = moment.utc(response.data.server_time).milliseconds(0);
      const newTimeDiff = moment.duration(serverTime.diff(moment.utc().milliseconds(0)));
      setTimeDiff(newTimeDiff);

      if (timer) {
        dispatch({
          type: 'FETCH_SUCCESS',
          payload: timer,
        });
      } else {
        dispatch({ type: 'FETCH_SUCCESS', payload: {} });

        setTimeout(() => {
        // Ask user to start timer
          if (window.confirm('Timer for patient is not running. Do you want to start the timer?')) {
            handleStartTimer(new Event('click')); // Simulate event
          }
        }, 500);
      }

      // Found running timer, notify the parent component
      onUpdate(!!timer);
    });
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      if (state.data.started_at) {
        const newTimeSpent = updateTimeSpent(state.data.started_at, timeDiff);

        setTimeSpent(newTimeSpent);

        if (showTimerAlert(newTimeSpent)) {
          const response = window.confirm('Timer for patient is still running. Do you want to stop the timer?');
          if (response) {
            // Stop the timer
            handleStopTimer(new Event('click')); // Simulate event
          }
        }
      } else {
        setTimeSpent(null);
      }
    }, 500);
  });

  if (state.isLoading) {
    // Show nothing while loading
    return '';
  }

  return (
    <>
      <span className="grey-text timer-container">
        {
          state.data.id
            ? (
              <>
                <span>{generateTimeString(timeSpent)}</span>
                <a className="btn waves-effect waves-light red white-text ml1 timer-button" href="#/" onClick={handleStopTimer}>
                  <i className="material-icons right">stop</i>
                  Stop timer
                </a>
              </>
            )
            : (
              <a className="btn waves-effect waves-light green white-text ml1 timer-button" href="#/" onClick={handleStartTimer}>
                <i className="material-icons right">play_arrow</i>
                Start timer
              </a>
            )
        }
      </span>
    </>
  );
};

Timer.propTypes = {
  onUpdate: PropTypes.func.isRequired,
};

export default Timer;
