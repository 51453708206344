import M from '@materializecss/materialize';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import { reducer, sendRequest } from '../utils';
import Loader from '../Components/Loader';

function SendRequest() {
  const history = useHistory();
  const [email, setEmail] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: false, isError: false,
    },
  );

  const onSubmitClick = async (e) => {
    e.preventDefault();
    dispatch({ type: 'FETCH_INIT' });

    const opts = {
      keyword: (email || `${firstName} ${lastName}`).trim(),
    };

    sendRequest('connections/patients/search', 'GET', opts)
      .then((response) => {
        const users = response.data;

        if (users.length) {
          dispatch({ type: 'FETCH_SUCCESS', payload: users });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: 'No users found', payload: [] });
        }
      });
  };

  const onCancelClick = (e) => {
    e.preventDefault();
    history.goBack();
  };

  React.useEffect(() => {
    M.updateTextFields();
  });

  return (
    <div className="container mt4 mb4">
      <h4 className="mb4">Send a New Request</h4>
      { state.isLoading
        ? <Loader />
        : (
          <>
            <div className="row">
              <form className="col s12">
                <div className={`form-messages ${state.message ? '' : 'hide'}`}>{state.message}</div>
                <div className={`form-errors ${state.error ? '' : 'hide'}`}>{state.error}</div>
                <div className="row">
                  <div className="input-field col s12">
                    <label htmlFor="email">Email</label>
                    <input
                      id="email"
                      type="email"
                      className="validate"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </div>
                </div>
                <span className="text-center">OR</span>
                <div className="row">
                  <div className="input-field col s6">
                    <label htmlFor="first_name">First Name</label>
                    <input
                      id="first_name"
                      type="text"
                      className="validate"
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                    />
                  </div>
                  <div className="input-field col s6">
                    <label htmlFor="last_name">Last Name</label>
                    <input
                      id="last_name"
                      type="text"
                      className="validate"
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                    />
                  </div>
                </div>
                <button type="submit" className="btn black mt2" onClick={onSubmitClick}>Search</button>
                <button type="button" className="btn white black-text ml2 mt2" onClick={onCancelClick}>Cancel</button>
              </form>
            </div>

            {
              state.data.length > 0 && (
              <div className="mt4">
                <h6 className="mb2">Found Patients:</h6>
                {state.data.map((u) => <Receiver key={u.id} user={u} />)}
              </div>
              )
              }
          </>
        )}
    </div>
  );
}

const Receiver = ({ user }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: false, isError: false,
    },
  );

  const onSendClick = (e) => {
    e.preventDefault();
    dispatch({ type: 'FETCH_INIT' });

    const opts = {
      receiver_id: user.id,
    };

    sendRequest('connections/requests', 'POST', opts)
      .then((response) => {
        if (response.status === 'success') {
          dispatch({ type: 'FETCH_SUCCESS', message: 'Request successfully sent' });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      });
  };

  if (state.message || state.error) {
    return (
      <div className="row">
        <div className="col s6" style={{ lineHeight: '36px', height: '36px' }}>{`${user.first_name} ${user.last_name || ''}`}</div>
        <div className="col s6 text-center" style={{ lineHeight: '36px', height: '36px' }}>
          <strong>{state.message || state.error}</strong>
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      {
      state.isLoading
        ? <Loader />
        : (
          <>
            <div className="col s10" style={{ lineHeight: '36px', height: '36px' }}>
              {`${user.first_name} ${user.last_name || ''} (${user.email})`}
            </div>
            <div className="col s2">
              <button type="submit" className="btn black" onClick={onSendClick}>Send</button>
            </div>
          </>
        )
    }
    </div>
  );
};

Receiver.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};

export default SendRequest;
