import M from '@materializecss/materialize';
import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import moment from 'moment';
import Loader from '../Components/Loader';
import Table from '../Components/Table';
import { reducer, sendRequest } from '../utils';
import { useUser } from '../hooks';

const PatientActions = ({ row: { original: cell } }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [patientId] = React.useState(cell.id);
  const [isFrozen, setIsFrozen] = React.useState(cell.is_frozen);

  const modalRemoveConnectionConfirm = React.useRef(null);
  const modalFreezePatientConfirm = React.useRef(null);

  React.useEffect(() => {
    if (modalRemoveConnectionConfirm.current) {
      M.Modal.init(modalRemoveConnectionConfirm.current, {});
    }

    if (modalFreezePatientConfirm.current) {
      M.Modal.init(modalFreezePatientConfirm.current, {});
    }
  });

  const handleFreezePatient = React.useCallback(() => {
    setIsLoading(true);

    sendRequest(`connections/patients/${patientId}/freeze`, 'PATCH')
      .then((response) => {
        if (response.status === 'error') {
          // eslint-disable-next-line no-console
          console.log(response.message);
        } else {
          setIsFrozen(true);
          setIsLoading(false);
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log('Something went wrong with freezing patient');
      });
  });

  const handleUnfreezePatient = () => {
    setIsLoading(true);

    sendRequest(`connections/patients/${patientId}/unfreeze`, 'PATCH')
      .then((response) => {
        if (response.status === 'error') {
          // eslint-disable-next-line no-console
          console.log(response.message);
        } else {
          setIsFrozen(false);
          setIsLoading(false);
          cell.is_frozen = false;
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log('Something went wrong with unfreezing patient');
      });
  };

  const handleRemoveConnection = () => {
    setIsLoading(true);

    sendRequest(`connections/patients/${patientId}`, 'DELETE')
      .then((response) => {
        if (response.status === 'error') {
          // eslint-disable-next-line no-console
          console.log(response.errors);
        } else {
          // Refresh page
          window.location.reload();
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log('Something went wrong with removing patient');
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  const freezeModalId = `freeze-modal-${cell.id}`;
  const removeModalId = `remove-modal-${cell.id}`;

  return (
    <>
      <Link to={`/patients/${patientId}`} className="btn black mb2">Open</Link>

      <br />

      {
      isFrozen
        ? <button type="button" className="btn white black-text mb2" onClick={handleUnfreezePatient}>Unfreeze</button>
        : <button type="button" data-target={freezeModalId} className="btn white black-text mb2 modal-trigger">Freeze</button>
      }

      <br />

      <button type="button" data-target={removeModalId} className="btn red modal-trigger">Remove</button>

      {/* Modals */}
      <div ref={modalRemoveConnectionConfirm} id={removeModalId} className="modal confirm-modal">
        <div className="modal-content">
          <h5>Remove Connection</h5>
          <p>Are you sure you want to remove this patient from the patient dashboard?</p>
        </div>
        <div className="modal-footer">
          <button type="button" className="modal-close waves-effect btn-flat" onClick={handleRemoveConnection}>Yes</button>
          <button type="button" className="modal-close waves-effect btn-flat">No</button>
        </div>
      </div>

      <div ref={modalFreezePatientConfirm} id={freezeModalId} className="modal confirm-modal">
        <div className="modal-content">
          <h5>Freeze Patient</h5>
          <p>
            Are you sure you want to freeze this patient? You will not receive any new measurements from the patient.
            Patient will still be on the dashboard, but will be marked as frozen.
          </p>
        </div>
        <div className="modal-footer">
          <button type="button" className="modal-close waves-effect btn-flat" onClick={handleFreezePatient}>Yes</button>
          <button type="button" className="modal-close waves-effect btn-flat">No</button>
        </div>
      </div>
    </>
  );
};

PatientActions.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const TooltippedCellValue = ({ row: { original }, column: { id } }) => {
  /* Add data-tip */
  const rawEntry = original[`${id}_raw`];

  if (rawEntry) {
    const date = rawEntry.date_to_date;
    const { time } = rawEntry;
    return <span className="tooltipped" data-position="top" data-tooltip={`${time}<br />${date}`}>{original[id]}</span>;
  }

  return <span>{original[id]}</span>;
};

TooltippedCellValue.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

const ChronicConditions = ({ row: { original: cell } }) => {
  const conditions = cell.chronic_conditions || [];

  return (
    <>
      {
        conditions.map((condition) => (
          <span key={condition} className="chip mr1">{condition}</span>
        ))
      }
    </>
  );
};

ChronicConditions.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      chronic_conditions: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
};

ChronicConditions.defaultProps = {
  row: {
    original: {
      chronic_conditions: '',
    },
  },
};

const prepareDataForTable = (rawPatients) => {
  // Form array of objects

  const data = rawPatients.map((rawPatient) => {
    const patientProfile = rawPatient.patient.patient_profile;
    const patientInfo = {
      id: rawPatient.patient.id,
      first_name: rawPatient.patient.first_name,
      last_name: rawPatient.patient.last_name || '-',
      chronic_conditions: patientProfile.chronic_conditions,
      dob: patientProfile.date_of_birth,
      // Parse date in format like 2021-04-27 20:09:00 UTC
      date_added: moment(rawPatient.created_at, 'YYYY-MM-DDTHH:mm:ssZ').format('MM/DD/YYYY hh:mm A'),
      // Parse date in format like 5/7/2000
      date_of_birth: (patientProfile.date_of_birth && moment(patientProfile.date_of_birth, 'YYYY-MM-DD').format('MM/DD/YYYY')) || '-',
      is_frozen: rawPatient.is_frozen,
      number_of_connected_devices: rawPatient.patient.number_of_connected_devices,
      needs_attention: patientProfile.needs_attention ? <i className="material-icons small red-text">check</i> : '',

      // Entries
      BloodPressure: '-',
      Weight: '-',
      Pulse: '-',
      PulseOximeter: '-',
      Thermometer: '-',
      BloodGlucose: '-',
    };

    let { entries } = rawPatient.patient;
    entries = Object.assign({}, ...entries);

    const BloodPressureLatest = entries.BloodPressure.latest;
    if (BloodPressureLatest) {
      patientInfo.BPM = `${BloodPressureLatest.systolic}/${BloodPressureLatest.diastolic} mmHg`;
      if (BloodPressureLatest.is_out_of_range) {
        patientInfo.BPM = <span className="out-of-alert-zone">{patientInfo.BPM}</span>;
      }
    }

    const WeightLatest = entries.Weight.latest;
    if (WeightLatest) {
      patientInfo.Weight = `${WeightLatest.weight} lbs`;
      if (WeightLatest.is_out_of_range) {
        patientInfo.Weight = <span className="out-of-alert-zone">{patientInfo.Weight}</span>;
      }
    }

    const PulseOximeterLatest = entries.PulseOximeter.latest;
    if (PulseOximeterLatest) {
      patientInfo.Pulse = `${PulseOximeterLatest.pulse} BPM`;
      if (PulseOximeterLatest.is_out_of_range) {
        patientInfo.Pulse = <span className="out-of-alert-zone">{patientInfo.Pulse}</span>;
      }

      patientInfo.PulseOximeter = `${PulseOximeterLatest.spo2}`;
      if (PulseOximeterLatest.is_out_of_range) {
        patientInfo.PulseOximeter = <span className="out-of-alert-zone">{patientInfo.PulseOximeter}</span>;
      }
    }

    const Thermometerlatest = entries.BodyTemperature.latest;
    if (Thermometerlatest) {
      patientInfo.Thermometer = `${Thermometerlatest.temperature} °F`;
      if (Thermometerlatest.is_out_of_range) {
        patientInfo.Thermometer = <span className="out-of-alert-zone">{patientInfo.Thermometer}</span>;
      }
    }

    const BloodGlucoseLatest = entries.BloodGlucose.latest;
    if (BloodGlucoseLatest) {
      patientInfo.BloodGlucose = `${BloodGlucoseLatest.glucose} mg/dL`;
      if (BloodGlucoseLatest.is_out_of_range) {
        patientInfo.BloodGlucose = <span className="out-of-alert-zone">{patientInfo.BloodGlucose}</span>;
      }
    }

    // Add formatting based on number of readings
    const numberOfDaysWithMeasurements = rawPatient.patient.number_of_days_with_measurements;

    // Patients with 0-7 readings marked in red
    // Patients with 8-12 readings in yellow
    // Patients with 13-16+ readings in green
    if (numberOfDaysWithMeasurements <= 15) {
      patientInfo.number_of_days_with_measurements = <strong className="red-text">{numberOfDaysWithMeasurements}</strong>;
    } else {
      patientInfo.number_of_days_with_measurements = <strong className="green-text">{numberOfDaysWithMeasurements}</strong>;
    }

    return {
      ...patientInfo,
      number_of_days_with_measurements: numberOfDaysWithMeasurements,
    };
  });

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      disableGlobalFilter: true,
    },
    {
      Header: 'First Name',
      accessor: 'first_name',
    },
    {
      Header: 'Last Name',
      accessor: 'last_name',
    },
    {
      Header: 'Chronic Conditions',
      accessor: 'chronic_conditions',
      Cell: ChronicConditions,
      disableSortBy: true,
      style: { maxWidth: '150px' },
      disableGlobalFilter: true,
    },
    {
      Header: 'Date of Birth',
      accessor: 'date_of_birth',
      disableGlobalFilter: true,
    },
    {
      Header: 'Blood Pressure',
      accessor: 'BPM',
      disableSortBy: true,
      className: 'center relative', /* This second class needed to fill all cell space ignoring paddings */
      Cell: TooltippedCellValue,
      disableGlobalFilter: true,
    },
    {
      Header: 'Weight',
      accessor: 'Weight',
      disableSortBy: true,
      className: 'center relative', /* The second class needed to fill all cell space ignoring paddings */
      Cell: TooltippedCellValue,
      disableGlobalFilter: true,
    },
    {
      Header: 'Pulse',
      accessor: 'Pulse',
      disableSortBy: true,
      className: 'center relative', /* The second class needed to fill all cell space ignoring paddings */
      Cell: TooltippedCellValue,
      disableGlobalFilter: true,
    },
    {
      Header: () => (
        <>
          SpO
          <sub>2</sub>
        </>
      ),
      accessor: 'PulseOximeter',
      label: 'SpO2',
      disableSortBy: true,
      className: 'center relative', /* The second class needed to fill all cell space ignoring paddings */
      Cell: TooltippedCellValue,
      disableGlobalFilter: true,
    },
    {
      Header: 'Temperature',
      accessor: 'Thermometer',
      disableSortBy: true,
      className: 'center relative', /* The second class needed to fill all cell space ignoring paddings */
      Cell: TooltippedCellValue,
      disableGlobalFilter: true,
    },
    {
      Header: 'Blood Glucose',
      accessor: 'BloodGlucose',
      disableSortBy: true,
      className: 'center relative', /* The second class needed to fill all cell space ignoring paddings */
      Cell: TooltippedCellValue,
      disableGlobalFilter: true,
    },
    {
      Header: '# of Days with Measurements',
      accessor: 'number_of_days_with_measurements',
      disableSortBy: false,
      disableGlobalFilter: true,
    },
    {
      Header: '# of devices',
      accessor: 'number_of_connected_devices',
      disableSortBy: true,
      disableGlobalFilter: true,
    },
    {
      Header: 'Needs Attention',
      accessor: 'needs_attention',
      disableSortBy: true,
      disableGlobalFilter: true,
    },
    {
      Header: 'Date Added',
      accessor: 'date_added',
      disableSortBy: true,
    },
    {
      Header: 'Actions',
      Cell: PatientActions,
      disableSortBy: true,
      disableGlobalFilter: true,
    },
  ];

  return { columns, data, withSearch: true, defaultSortBy: 'last_name' };
};

const PatientsList = () => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], isLoading: true, isError: false },
  );
  const user = useUser();

  const [filter, setFilter] = React.useState('all');
  // const [patients, setPatients] = React.useState([]);

  const handleFetchPatients = () => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('connections/patients', 'GET')
      .then((response) => {
        // Update list of patients
        const filteredPatients = response.data.filter((patient) => {
          if (filter === 'no-time-spent') {
            return patient.time_trackers.length === 0;
          }

          if (filter === 'inactive') {
            return patient.patient.inactive;
          }

          if (filter === 'out-of-range') {
            let outOfRange = false;
            const { entries } = patient.patient;

            entries.forEach((entryInfo) => {
              const latestEntry = Object.values(entryInfo)[0].latest;

              if (latestEntry && latestEntry.is_out_of_range) {
                outOfRange = true;
              }
            });

            return outOfRange;
          }

          if (filter === 'frozen') {
            return patient.is_frozen;
          }

          return true;
        });

        dispatch({
          type: 'FETCH_SUCCESS',
          payload: filteredPatients,
        });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  };

  React.useEffect(() => {
    handleFetchPatients();
  }, [filter]);

  React.useEffect(() => {
    // Initiate tooltips
    // TODO: Refactor?
    M.Tooltip.init(document.querySelectorAll('.tooltipped'), {});
  }, [state.data]);

  return (
    <div className="container mt4 mb4">
      {
        user && user.doctor_profile.practice && (
          <h3 className="mt4 mb4">
            {user.doctor_profile.practice.name}
          </h3>
        )
      }

      <h4 className="header-with-button mt4 mb4">
        Patients

        {user && user.is_staff && (
          <a href="/invite-patient" className="btn btn-small white black-text right hide-on-print">Add new patient</a>
        )}
      </h4>

      <div>
        {state.isError && <p className="form-errors">{state.error}</p>}

        <UnfinishedTimersWarning patients={state.data} />

        <UnfinishedActionsWarning />

        {/* Cards */}
        <div className="row">
          <div className="col s12 m6 l4">
            <div className="card">
              <div className="card-content">
                <span className="card-title text-center">Total patients</span>
                <p className="h3 text-center">{state.data.length}</p>
              </div>
            </div>
          </div>

          <div className="col s12 m6 l4">
            <div className="card">
              <div className="card-content">
                <span className="card-title text-center">Patients with no time spent</span>
                <p className="h3 text-center">{state.data.filter((p) => p.time_trackers.length === 0).length}</p>
              </div>
            </div>
          </div>

          <div className="col s12 m6 l4">
            <div className="card">
              <div className="card-content">
                <span className="card-title text-center">Patients needs attention</span>
                <p className="h3 text-center">{state.data.filter((p) => p.patient.patient_profile.needs_attention).length}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt4 mb4">
          <button
            className={`btn black mr1 ${filter === 'all' ? 'disabled' : ''}`}
            type="button"
            onClick={() => setFilter('all')}
          >
            All Patients
          </button>
          <button
            className={`btn black mr1 ${filter === 'out-of-range' ? 'disabled' : ''}`}
            type="button"
            onClick={() => setFilter('out-of-range')}
          >
            Out-of-Range Patients
          </button>
          <button
            className={`btn black mr1 ${filter === 'no-time-spent' ? 'disabled' : ''}`}
            type="button"
            onClick={() => setFilter('no-time-spent')}
          >
            Patients With No Time Spent
          </button>

          <button
            className={`btn black mr1 ${filter === 'frozen' ? 'disabled' : ''}`}
            type="button"
            onClick={() => setFilter('frozen')}
          >
            Frozen Patients
          </button>

          <button
            className={`btn black mr1 ${filter === 'inactive' ? 'disabled' : ''}`}
            type="button"
            onClick={() => setFilter('inactive')}
          >
            Inactive Patients
          </button>
        </div>

        {state.isLoading ? (
          <Loader />
        ) : (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <>
            <Table {...prepareDataForTable(state.data)} centered classes="patients-table" />
          </>
        )}
      </div>

    </div>
  );
};

const UnfinishedTimersWarning = ({ patients }) => {
  const patientsWithUnfinishedTimers = patients.filter(
    (p) => p.time_trackers.length && p.time_trackers.filter((t) => !t.ended_at).length,
  );

  const patientsToShow = patientsWithUnfinishedTimers.map((p) => ({
    id: p.patient.id,
    name: `${p.patient.first_name} ${p.patient.last_name || ''}`,
  }));

  return (
    <>
      {
        patientsToShow.length > 0 && (
        <div className="form-errors red-text pb3 pt3" style={{ fontSize: '18px' }}>
          <i className="material-icons pr2" style={{ fontSize: '30px' }}>warning</i>
          You have running timers for the following patients:&nbsp;
          {
            patientsToShow.map(
              (p) => <Link key={p.id} to={`/patients/${p.id}`} className="">{p.name}</Link>,
            ).reduce((prev, curr) => [prev, ', ', curr]) // Split with commas
          }
        </div>
        )
      }
    </>
  );
};

UnfinishedTimersWarning.propTypes = {
  patients: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const UnfinishedActionsWarning = () => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], isLoading: true, isError: false },
  );

  const handleFetchAssignedActions = () => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('actions/assigned', 'GET')
      .then((response) => {
        const incompletedActions = response.data.filter((a) => !a.completed);

        // Group by patient ID
        const patientsWithActions = incompletedActions.reduce((acc, curr) => {
          acc[curr.patient.id] = `${curr.patient.first_name} ${curr.patient.last_name}`;
          return acc;
        }, {});

        dispatch({
          type: 'FETCH_SUCCESS',
          payload: Object.entries(patientsWithActions),
        });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  };

  React.useEffect(() => {
    handleFetchAssignedActions();
  }, []);

  return (
    <>
      {
        state.data.length > 0 && (
        <div className="form-warnings">
          You have incompleted actions for the following patients:&nbsp;
          {
            state.data.map(
              ([patientId, patientName]) => (
                <Link key={patientId} to={`/patients/${patientId}`} target="_blank">{patientName}</Link>
              ),
            ).reduce((prev, curr) => [prev, ', ', curr]) // Split with commas
          }
          &nbsp;
          <a href="/actions" target="_blank" className="btn black ml2">View all</a>
        </div>
        )
      }
    </>
  );
};

export default PatientsList;
