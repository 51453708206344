import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Editable from '../../Components/Editable';
import Select from '../../Components/Select';
import Table from '../../Components/Table';
import { reducer, sendRequest } from '../../utils';

const CreatedAt = ({ row: { original: cell } }) => (
  <div>{moment.utc(cell.created_at, 'YYYY-MM-DD h:mm:ss').local().format('LLL')}</div>
);

CreatedAt.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.number.isRequired,
      created_at: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const Actions = ({ patientId, callbackActions }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', reload: false, isLoading: false, isError: false,
    },
  );

  const [assigness, setAssignees] = React.useState([]);

  const [newAction, setNewAction] = React.useState('');
  const [assigneeId, setAssigneeId] = React.useState(null);

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest(`connections/patients/${patientId}/actions`, 'GET')
      .then((response) => {
        const actions = response.data.map(
          (a) => ({
            id: a.id,
            created_at: a.created_at,
            action: a.action,
            assignee: `${a.assignee ? a.assignee.first_name : ''} ${a.assignee ? a.assignee.last_name : ''}`,
            assignee_id: a.assignee?.id,
            status: a.status,
          }),
        );

        callbackActions(actions);

        dispatch({
          type: 'FETCH_SUCCESS',
          payload: actions,
          reload: false,
        });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });

    sendRequest('connections/colleagues', 'GET')
      .then((resp) => {
        const assignees = resp.data.map(
          (c) => ([c.id, `${c.first_name} ${c.last_name}`]),
        );

        assignees.unshift([null, 'Select an assignee']);

        setAssignees(assignees);
      });
  }, [state.reload]);

  const onAddNewAction = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    if (assigneeId === null) {
      dispatch({ type: 'FETCH_FAILURE', error: 'Please select an assignee' });
      return;
    }

    const opts = {
      assignee_id: assigneeId,
      action: newAction,
    };

    sendRequest(`connections/patients/${patientId}/actions`, 'POST', opts)
      .then((resp) => {
        if (resp.status === 'success') {
          dispatch({
            type: 'FETCH_SUCCESS',
            // payload: resp.Notes,
            reload: true,
          });

          // Reset new action content
          setNewAction('');
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: resp.message });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  };

  const onChangeAction = (actionId, status, content) => {
    dispatch({ type: 'FETCH_INIT' });

    if (status === 1) {
      dispatch({ type: 'FETCH_FAILURE', error: 'Action cannot be marked as incomplete' });
      return;
    }

    sendRequest(`connections/patients/${patientId}/actions/${actionId}/complete`, 'PATCH', { action: content })
      .then((resp) => {
        if (resp.status === 'success') {
          dispatch({
            type: 'FETCH_SUCCESS',
            reload: true,
          });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: resp.message });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  };

  const StatusActions = ({ row: { original: cell } }) => (
    <div>
      <label>
        <input
          type="checkbox"
          defaultChecked={cell.status === 2}
          onChange={(e) => onChangeAction(cell.id, e.target.checked ? 2 : 1, cell.content)}
        />
        <span />
      </label>
    </div>
  );

  StatusActions.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.number,
        status: PropTypes.number,
      }),
    }).isRequired,
  };

  const ActionContent = ({ row: { original: cell } }) => (
    <Editable
      defaultValue={cell.action}
      onChange={(newContent) => onChangeAction(cell.id, cell.status, newContent)}
    />
  );

  ActionContent.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.number,
        status: PropTypes.number,
      }),
    }).isRequired,
  };

  const columns = [
    {
      Header: 'Action',
      Cell: ActionContent,
      accessor: 'action',
    },
    {
      Header: 'Assignee',
      accessor: 'assignee',
    },
    {
      Header: 'Created On',
      Cell: CreatedAt,
      accessor: 'created_at',
    },
    {
      Header: 'Status',
      Cell: StatusActions,
      accessor: 'status',
    },
  ];

  return (
    <>
      <h5 className="mt4 mb4">Actions</h5>

      {state.isError && <p className="form-errors">{state.error}</p>}

      <div className="row">
        <form className="col s12">

          <div className="row">
            <div className="input-field col s9">
              <textarea
                id="new-action"
                name="new-action"
                className="materialize-textarea validate"
                value={newAction}
                onChange={(e) => setNewAction(e.target.value)}
              />
              <label htmlFor="new-action">Action</label>
            </div>

            <div className="input-field col s3">
              <Select
                name="assignee"
                label="Assignee"
                values={assigness}
                defaultValue={assigneeId}
                onChange={(e) => setAssigneeId(e.target.value)}
                required
              />
              <span className="helper-text" data-error="wrong" data-success="right">
                Add new assignees&nbsp;
                <Link to="/colleagues" target="_blank">here</Link>
              </span>
            </div>

            <div className="col s12">
              <button type="submit" value="submit" className="btn black" onClick={onAddNewAction}>Add</button>
            </div>
          </div>

        </form>
      </div>

      {state.data.length > 0
        ? (
          <Table columns={columns} data={state.data} centered defaultPageSize={10} defaultSortBy="created_at" defaultSortDesc />
        )
        : (
          <p className="form-messages">No actions found</p>
        )}

    </>
  );
};

Actions.propTypes = {
  patientId: PropTypes.string.isRequired,
  callbackActions: PropTypes.func.isRequired,
};

export default Actions;
