import M from '@materializecss/materialize';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Prompt, useHistory, useLocation, useParams } from 'react-router';

import AlertZones from './Settings/AlertZones/List';
import CarePlan from './CarePlan/List';
import CommonInfo from './CommonInfo';
import Loader from '../Components/Loader';
import LoginMessage from '../Components/LoginMessage';
import PatientDetails from './Details';
import Summary from './Summary/Summary';
import Timer from './Timer';

import { TimerStatusContext } from '../Context';
import { reducer, sendRequest, useAuth } from '../utils';
import { useUser } from '../hooks';

const DEFAULT_TABS_ORDER = ['data', 'careplan', 'summary', 'settings', 'info'];
const TABS = {
  careplan: 'Care Plan',
  data: 'Data',
  info: 'Info',
  settings: 'Settings',
  summary: 'Summary',
};

const Patient = () => {
  const [logged] = useAuth();
  const { patientId } = useParams();
  const history = useHistory();

  // This state variable is used to prevent the user from navigating away from the page
  const [timerStatus, setTimerStatus] = React.useState(false);

  // This state variable is used to store current page path
  const [locationPath, setLocationPath] = React.useState(window.location.pathname);

  const [state, dispatch] = React.useReducer(
    reducer,
    { data: {}, isLoading: true, isError: false },
  );

  const handleFetchPatient = React.useCallback(async () => {
    dispatch({ type: 'FETCH_INIT' });

    if (logged) {
      sendRequest(`connections/patients/${patientId}`, 'GET')
        .then((response) => {
          if (response.status === 'error') {
            dispatch({ type: 'FETCH_FAILURE', error: response.message });
          } else {
            dispatch({
              type: 'FETCH_SUCCESS',
              payload: response.data,
            });
          }
        })
        .catch((error) => {
          dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
        });
    }
  }, [patientId, logged]);

  React.useEffect(() => {
    handleFetchPatient();
  }, []);

  React.useEffect(() => {
    if (state.data?.patient?.first_name) {
      document.title = `Patients - ${state.data.patient.first_name} ${state.data.patient.last_name || ''}`;
      return;
    }
    document.title = 'Patients - Loading...';
  }, [state.data]);

  const generatePrompt = React.useCallback((location) => {
    if (!location.pathname.startsWith(locationPath)) {
      return (
        'Please note you are still tracking time for this patient. '
        + "Please don't forget to stop the timer. "
        + "Click 'Cancel' to stay on the page or 'OK' to leave the page."
      );
    }

    return true;
  }, []);

  React.useEffect(() => history.listen((location) => {
    setLocationPath(location.pathname);
  }), []);

  const handleTimerUpdate = React.useCallback((status) => {
    setTimerStatus(status);
  }, []);

  return (
    <div className="container mt4 mb4">
      <TimerStatusContext.Provider value={timerStatus}>
        <div>
          {!logged
            ? <LoginMessage />
            : (
              <div>
                {state.isError && <p>Something went wrong ...</p>}

                {state.isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <Prompt
                      when={timerStatus}
                      message={generatePrompt}
                    />
                    <div>
                      <h4 className="mt2 mb2 d-inline-block pr2">
                        Patient:
                        <strong>{` ${state.data.patient?.first_name} ${state.data.patient?.last_name || ''}`}</strong>
                      </h4>
                      <Timer onUpdate={handleTimerUpdate} />
                    </div>
                    <PatientInfo patient={state.data} />
                  </>
                )}
              </div>
            )}
        </div>
      </TimerStatusContext.Provider>
    </div>
  );
};

const PatientInfo = ({ patient }) => {
  const history = useHistory();
  const { hash } = useLocation();

  const user = useUser();

  const [tabs, setTabs] = React.useState([]);

  const [activeTab, setActiveTab] = React.useState(null);
  const [loadedTabs, setLoadedTabs] = React.useState([]);

  const [filters, setFilters] = React.useState({
    interval: 'weekly',
    period: 0, // Only for custom interval
    startDate: moment().subtract(7, 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    endDate: moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toDate(),
  });

  const handleTabClick = (id) => {
    setActiveTab(id);

    // Mark the tab as loaded
    if (!loadedTabs.includes(id)) {
      setLoadedTabs((prevLoadedTabs) => [...prevLoadedTabs, id]);
    }
  };

  const onFiltersChange = (newFilters) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));

    // Reset loaded tabs
    setLoadedTabs([activeTab]);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(tabs);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const payload = {
      portal_tabs_order: items,
    };

    sendRequest('users/me/config_change', 'PUT', payload)
      .then((response) => {
        if (response.status === 'success') {
          setTabs(items);
        } else {
          alert('Failed to save tabs order. Please try again.');
        }
      });
  };

  React.useEffect(() => {
    // Use small delay because React.js need some time to render tabs
    setTimeout(() => M.Tabs.init(
      document.querySelector('.patient-tabs'),
      {
        onShow: (tab) => {
          history.push({ hash: tab.getAttribute('id') });
        },
      },
    ), 100);
  });

  React.useEffect(() => {
    if (user) {
      const userConfig = user.config || {};
      const userTabs = userConfig.portal_tabs_order || DEFAULT_TABS_ORDER;

      setTabs(userTabs);

      // Set active tab and loaded tabs
      const activeTab = hash.slice(1) || userTabs[0];
      setActiveTab(activeTab);
      setLoadedTabs([activeTab]);
    }
  }, [user]);

  React.useEffect(() => {

  }, [tabs]);

  if (!user) {
    return <Loader />;
  }

  return (
    <div className="row">
      <div className="col s12">

        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="tabs" direction="horizontal">
            {(provided) => (
              <ul
                className="tabs tabs-fixed-width patient-tabs"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {tabs.map((tab, index) => (
                  <Draggable key={tab} draggableId={tab} index={index}>
                    {(provided) => (
                      <li
                        className="tab col s3"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <a
                          className={activeTab === index ? 'active' : ''}
                          href={`#${tab}`}
                          onClick={() => handleTabClick(tab)}
                        >
                          {TABS[tab]}
                        </a>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>

      </div>
      <div id="summary" className="col s12 pt2">
        {loadedTabs.includes('summary') && (
          <Summary
            patientId={patient.patient.id}
            filters={filters}
            onFiltersChange={onFiltersChange}
          />
        )}
      </div>
      <div id="careplan" className="col s12">
        {loadedTabs.includes('careplan') && (
          <CarePlan patient={patient} />
        )}
      </div>
      <div id="info" className="col s12">
        {loadedTabs.includes('info') && (
          <CommonInfo patient={patient} />
        )}
      </div>
      <div id="data" className="col s12 pt2">
        {loadedTabs.includes('data') && (
          <PatientDetails
            patient={patient}
            filters={filters}
            onFiltersChange={onFiltersChange}
          />
        )}
      </div>
      <div id="settings" className="col s12">
        {loadedTabs.includes('settings') && (
          <AlertZones patientId={patient.patient.id} />
        )}
      </div>
    </div>
  );
};

PatientInfo.propTypes = {
  patient: PropTypes.shape({
    patient: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Patient;
