import M from '@materializecss/materialize';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { useAuth, sendRequest } from '../utils';

function Register() {
  const [logged] = useAuth();
  const history = useHistory();
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    M.AutoInit();
  }, []);

  if (logged) {
    return <Redirect to="/" />;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const opts = Object.fromEntries(new FormData(e.target));

    opts.role = 'Doctor';
    opts.doctor_profile = {
      practice: {
        name: opts.practice_name,
      },
      job_title: '',
    };

    delete opts.practice_name;

    sendRequest('users/register', 'POST', opts, false)
      .then((response) => {
        if (response.status === 'success') {
          history.push('/login');
        } else {
          setError(response.message);
        }
      })
      .catch(() => setError('Something went wrong'));
  };

  return (
    <div className="container mt4 mb4">
      <h4 className="mb4">Register</h4>
      <p>
        Already have an account?
        {' '}
        <a href="/login">Login</a>
      </p>
      <div className="row">
        <form className="col s12" onSubmit={handleSubmit}>
          <div className={`form-errors ${error ? '' : 'hide'}`}>{error}</div>
          <div className="row">
            <div className="input-field col s12 m6">
              <input
                id="first_name"
                name="first_name"
                type="text"
                className="validate"
                required
              />
              <label htmlFor="first_name">First Name</label>
            </div>
            <div className="input-field col s12 m6">
              <input
                id="last_name"
                name="last_name"
                type="text"
                className="validate"
                required
              />
              <label htmlFor="last_name">Last Name</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <input
                id="practice_name"
                name="practice_name"
                type="text"
                className="validate"
                required
              />
              <label htmlFor="practice_name">Practice&apos;s Name</label>
              <span className="helper-text" data-error={error} />
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <input
                id="email"
                name="email"
                type="email"
                className="validate"
                required
              />
              <label htmlFor="email">Email</label>
              <span className="helper-text" data-error={error} />
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <input
                id="password"
                name="password"
                type="password"
                className="validate"
                required
              />
              <label htmlFor="password">Password</label>
            </div>
          </div>

          <button type="submit" className="btn black mt2">Register</button>
        </form>
      </div>
    </div>
  );
}

export default Register;
