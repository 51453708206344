import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Loader from '../Components/Loader';
import Table from '../Components/Table';
import { reducer, sendRequest } from '../utils';

const CreatedAt = ({ row: { original: cell } }) => (
  <div>{moment.utc(cell.created_at, 'YYYY-MM-DD h:mm:ss').local().format('LLL')}</div>
);

CreatedAt.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.number.isRequired,
      created_at: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const AssignedActions = () => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', reload: false, isLoading: false, isError: false,
    },
  );

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('actions/assigned', 'GET')
      .then((resp) => {
        const actions = resp.data.map(
          (a) => ({
            id: a.id,
            created_at: a.created_at,
            patient_id: a.patient.id,
            patient_name: `${a.patient.first_name} ${a.patient.last_name}`,
            patient_url: `/patients/${a.patient_id}`,
            content: a.action,
            completed: a.status === 2,
          }),
        );

        dispatch({
          type: 'FETCH_SUCCESS',
          payload: actions,
          reload: false,
        });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  }, [state.reload]);

  const onChangeAction = (action) => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest(`connections/patients/${action.patient_id}/actions/${action.id}/complete`, 'PATCH', {})
      .then((resp) => {
        if (resp.status === 'success') {
          dispatch({
            type: 'FETCH_SUCCESS',
            reload: true,
          });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: resp.message });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  };

  const columns = [
    {
      Header: 'Patient',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: cell } }) => (
        <a href={cell.patient_url} target="_blank" rel="noreferrer">{cell.patient_name}</a>
      ),
      accessor: 'patient_name',
    },
    {
      Header: 'Action',
      accessor: 'content',
      style: { whiteSpace: 'pre-wrap' },
    },
    {
      Header: 'Created At',
      Cell: CreatedAt,
      accessor: 'created_at',
    },
    {
      Header: 'Status',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: cell } }) => (
        <div>
          <label>
            <input
              type="checkbox"
              defaultChecked={cell.completed}
              onChange={() => onChangeAction(cell)}
              disabled={cell.completed}
            />
            <span />
          </label>
        </div>
      ),
      accessor: 'completed',
    },
  ];

  if (state.isLoading) {
    return <Loader />;
  }

  return (
    <div className="container mt4 mb4">
      <h5 className="mt4 mb4">Assigned Actions</h5>

      {state.isError && <p className="form-errors">{state.error}</p>}

      {state.data.length > 0
        ? (
          <Table columns={columns} data={state.data} centered />
        )
        : (
          <p className="form-messages">No actions found</p>
        )}

    </div>
  );
};

AssignedActions.propTypes = {
  // patientId: PropTypes.string.isRequired,
};

export default AssignedActions;
