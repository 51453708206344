const ENTRY_TYPE_TO_CODE_MAPPING = {
  BloodPressure: 1,
  PulseOximeter: 2,
  Weight: 3,
  BloodGlucose: 4,
  ECG: 5,
  Medication: 6,
  Steps: 7,
  Calories: 8,
  Water: 9,
  PainLevel: 10,
  Sleep: 11,
  BodyTemperature: 12,
};

const ENTRY_CODE_TO_TYPE_MAPPING = {
  1: 'BloodPressure',
  2: 'PulseOximeter',
  3: 'Weight',
  4: 'BloodGlucose',
  5: 'ECG',
  6: 'Medication',
  7: 'Steps',
  8: 'Calories',
  9: 'Water',
  10: 'PainLevel',
  11: 'Sleep',
  12: 'BodyTemperature',
};

const ENTRY_TYPES_LABELS = {
  1: 'Blood Pressure',
  2: 'Pulse Oximeter',
  3: 'Weight',
  4: 'Blood Glucose',
  5: 'ECG',
  6: 'Medication',
  7: 'Steps',
  8: 'Calories',
  9: 'Water',
  10: 'Pain Level',
  11: 'Sleep',
  12: 'Body Temperature',
};

export { ENTRY_CODE_TO_TYPE_MAPPING, ENTRY_TYPE_TO_CODE_MAPPING, ENTRY_TYPES_LABELS };
