import React from 'react';

import M from '@materializecss/materialize';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { reducer, sendRequest } from '../../../utils';
import Loader from '../../../Components/Loader';
import Select from '../../../Components/Select';
import TimeSelector from './TimeSelector';

const DAYS = {
  // Every: 'Every',
  Sunday: 'Sunday',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
};

const TYPES = {
  1: 'Blood Pressure',
  2: 'Pulse Oximeter',
  3: 'Weight',
  4: 'Blood Glucose',
  5: 'ECG',
  // 6: 'Medication',
  // 7: 'Steps',
  // 8: 'Calories',
  // 9: 'Water',
  // 10: 'PainLevel',
  // 11: 'Sleep',
  12: 'Body Temperature',
};

const AddReminder = () => {
  const { patientId } = useParams();
  const history = useHistory();

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isError: false, isLoading: false,
    },
  );
  const [times, setTimes] = React.useState([]); // ['10:00 AM', '11:00 AM']

  const handleTimesChange = (times) => {
    setTimes(times);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    const opts = Object.fromEntries(new FormData(e.target));

    const daysSelect = e.target.querySelector('select[name="reminder_days"]');
    // const typesSelect = e.target.querySelector('select[name="entry_type"]');

    const days = M.FormSelect.getInstance(daysSelect).getSelectedValues();

    opts.reminder_days = days.join(',');
    // opts.entry_type = Number(types);

    if (!times.length) {
      dispatch({ type: 'FETCH_FAILURE', error: 'Please select reminder time(s)' });
      return;
    }

    Promise.all(times.map((time) => {
      // Time should be in UTC
      opts.reminder_time = moment(time, 'h:m a').utc().format('H:mm');
      return sendRequest(`connections/patients/${patientId}/reminders`, 'POST', opts);
    }))
      .then((responses) => {
        const errors = responses.filter((r) => r.status !== 'success').map((r) => r.message);
        if (errors.length) {
          dispatch({ type: 'FETCH_FAILURE', error: errors.join(', ') });
        } else {
          // Go back to the previous page
          history.goBack();
        }
      })
      .catch(() => dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' }));
  };

  React.useEffect(() => {
    M.updateTextFields();
    M.AutoInit();
  });

  return (
    <div className="container mt4 mb4">
      <h5 className="header mt4">
        New Reminder
      </h5>

      {
        state.isLoading
          ? <Loader />
          : (
            <div className="row">
              <form className="col s12" onSubmit={handleSubmit}>
                <div className={`form-messages ${state.message ? '' : 'hide'}`}>{state.message}</div>
                <div className={`form-errors ${state.isError ? '' : 'hide'}`}>{state.error}</div>

                <div className="row">
                  <div className="input-field col s12 m12">
                    <Select
                      name="reminder_days"
                      label="Days"
                      values={DAYS}
                      multiple
                    />
                  </div>
                </div>

                <TimeSelector onChange={handleTimesChange} />

                <div className="row">
                  <div className="input-field col s12 m12">
                    <Select
                      name="entry_type"
                      label="Entry Type"
                      values={TYPES}
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="input-field col s12 m12">
                    <textarea name="reminder_notes" className="materialize-textarea" />
                    <label htmlFor="reminder_notes">Notes</label>
                  </div>
                </div>

                <button type="submit" className="btn black mt2 ml2">Save</button>
                <button
                  type="button"
                  value="close"
                  className="btn white black-text mt2 ml2"
                  onClick={() => history.goBack()}
                >
                  Close
                </button>
              </form>
            </div>
          )
      }
    </div>
  );
};

AddReminder.propTypes = {};

export default AddReminder;
