import React from 'react';
import M from '@materializecss/materialize';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from '../Components/Select';

const RANGE_BUTTONS = [
  { id: 'daily', text: 'Day', delta: 'day' },
  { id: 'weekly', text: 'Week', delta: 'isoWeek' },
  { id: 'monthly', text: 'Month', delta: 'month' },
  { id: 'yearly', text: 'Year', delta: 'year' },
  { id: 'total', text: 'Total', delta: null },
  { id: 'custom', text: 'Custom', delta: null, classes: 'hide' },
];

const PERIODS = {
  0: 'Select period',
  7: '7 days',
  14: '14 days',
  21: '21 days',
  28: '28 days',
  30: '30 days',
  60: '60 days',
  90: '90 days',
};

const RangeButtonsGroup = ({ defaultInterval, defaultPeriod = 0, initialStartDate = null, initialEndDate = null, callback }) => {
  const [interval, setIntervalState] = React.useState(defaultInterval);
  const [startDate, setStartDate] = React.useState(
    initialStartDate || moment().subtract(1, RANGE_BUTTONS.find((b) => b.id === defaultInterval)?.delta || 'day').toDate(),
  );
  const [endDate, setEndDate] = React.useState(initialEndDate || moment().toDate());
  const [period, setPeriod] = React.useState(defaultPeriod || 0);

  const idPostfix = React.useMemo(() => (new Date()).getMilliseconds(), []);
  const startDateButtonId = `date_start-${idPostfix}`;
  const endDateButtonId = `date_end-${idPostfix}`;

  const handleButtonClick = (button) => {
    const newDate = moment();
    let newStartDate = null;
    let newEndDate = null;

    if (button.delta) {
      newEndDate = newDate.toDate();
      newStartDate = newDate.subtract(1, button.delta).toDate();
    } else if (button.id === 'total') {
      newEndDate = newDate.toDate();
      newStartDate = newDate.subtract(2, 'years').toDate();
    }

    setIntervalState(button.id);
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setPeriod(0);
  };

  const handlePeriodChange = (e) => {
    const period = Number(e.target.value);
    if (period) {
      const newStartDate = moment().subtract(period, 'days'); // Start date based on the period
      const newEndDate = moment(); // Current date

      setStartDate(newStartDate.toDate());
      setEndDate(newEndDate.toDate());
      setIntervalState('custom');
      setPeriod(period);
    }
  };

  const handleDateChange = (newDate, dateType) => {
    if (dateType === 'start') {
      setStartDate(newDate);
    } else {
      setEndDate(newDate);
    }
    setIntervalState('custom');
  };

  React.useEffect(() => {
    M.Datepicker.init(
      document.querySelector(`#${startDateButtonId}`),
      {
        defaultDate: startDate,
        setDefaultDate: true,
        autoClose: true,
        onSelect: (newDate) => handleDateChange(newDate, 'start'),
      },
    );
    M.Datepicker.init(
      document.querySelector(`#${endDateButtonId}`),
      {
        defaultDate: endDate,
        setDefaultDate: true,
        autoClose: true,
        onSelect: (newDate) => handleDateChange(newDate, 'end'),
      },
    );
  }, [startDate, endDate, startDateButtonId, endDateButtonId]);

  React.useEffect(() => {
    callback({ interval, period, startDate, endDate });
  }, [interval, period, startDate, endDate]);

  return (
    <div className="mt4">
      <div>
        {RANGE_BUTTONS.map((b) => (
          <RangeButton
            key={b.id}
            button={b}
            isActive={interval === b.id}
            handleButtonClick={handleButtonClick}
          />
        ))}
      </div>
      <div className="row">
        <form className="col s12">
          <div className="row">
            <div className="input-field col s4">
              <label htmlFor={startDateButtonId}>Start Date</label>
              <input id={startDateButtonId} type="text" className="datepicker" />
            </div>
            <div className="input-field col s4">
              <label htmlFor={endDateButtonId}>End Date</label>
              <input id={endDateButtonId} type="text" className="datepicker" />
            </div>
            <div className="input-field col s4">
              <Select
                label="Period"
                name="period"
                values={PERIODS}
                defaultValue={period}
                onChange={handlePeriodChange}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

RangeButtonsGroup.propTypes = {
  defaultInterval: PropTypes.string.isRequired,
  defaultPeriod: PropTypes.number,
  initialStartDate: PropTypes.instanceOf(Date),
  initialEndDate: PropTypes.instanceOf(Date),
  callback: PropTypes.func.isRequired,
};

RangeButtonsGroup.defaultProps = {
  defaultPeriod: 0,
  initialStartDate: null,
  initialEndDate: null,
};

const RangeButton = ({ button, isActive, handleButtonClick }) => (
  <a
    href="#/"
    className={`range-button btn black mr1 mb2 ${isActive ? 'disabled' : ''} ${button.classes || ''}`}
    onClick={(e) => {
      e.preventDefault();
      handleButtonClick(button);
    }}
  >
    {button.text}
  </a>
);

RangeButton.propTypes = {
  button: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    classes: PropTypes.string,
    delta: PropTypes.string,
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
};

export default RangeButtonsGroup;
