import M from '@materializecss/materialize';
import React from 'react';

import { sendRequest } from '../utils';

function GeneralSettingsForm() {
  const [message, setMessage] = React.useState('');
  const [error, setError] = React.useState('');

  const [user, setUser] = React.useState({});

  React.useEffect(() => {
    M.updateTextFields();
  }, [user]);

  React.useEffect(() => {
    sendRequest('users/me', 'GET')
      .then((resp) => {
        setUser(resp.data.doctor_profile.practice);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    // Leave only fields presented in Office Details form + user ID
    const opts = {
      doctor_profile: {
        practice: {
          name: user.name,
          office_phone_number: user.office_phone_number,
          office_email: user.office_email,
          office_address: user.office_address,
          office_hours: user.office_hours,
          inactive_patients_timeout: user.inactive_patients_timeout,
        },
      },
    };

    sendRequest('users/me', 'PUT', opts)
      .then((response) => {
        if (response.status === 'success') {
          setMessage('Settings updated successfully');
        } else {
          setError(response.message);
        }
      })
      .catch(() => setError('Something went wrong'));
  };

  return (
    <div>
      <div className="row">
        <h5 className="mb2">General</h5>
        <form className="col s12" onSubmit={handleSubmit}>
          <div className={`form-messages ${message ? '' : 'hide'}`}>{message}</div>
          <div className={`form-errors ${error ? '' : 'hide'}`}>{error}</div>

          <div className="row">
            <div className="input-field col s12 m12">
              <input
                id="practice_name"
                name="practice_name"
                type="text"
                className="validate"
                value={user.name || ''}
                onChange={(e) => setUser({ ...user, name: e.target.value })}
              />
              <label htmlFor="practice_name">Practice&apos;s name</label>
            </div>
          </div>

          <div className="row">
            <h5 className="mb2">Office</h5>
            <div className="input-field col s12 m6">
              <input
                id="office_phone_number"
                name="office_phone_number"
                type="text"
                className="validate"
                value={user.office_phone_number || ''}
                onChange={(e) => setUser({ ...user, office_phone_number: e.target.value })}
              />
              <label htmlFor="office_phone_number">Phone Number</label>
            </div>

            <div className="input-field col s12 m6">
              <input
                id="office_email"
                name="office_email"
                type="email"
                className="validate"
                value={user.office_email || ''}
                onChange={(e) => setUser({ ...user, office_email: e.target.value })}
              />
              <label htmlFor="office_email">Email Address</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12 m6">
              <input
                id="office_address"
                name="office_address"
                type="text"
                className="validate"
                value={user.office_address || ''}
                onChange={(e) => setUser({ ...user, office_address: e.target.value })}
              />
              <label htmlFor="office_address">Address</label>
            </div>

            <div className="input-field col s12 m6">
              <input
                id="office_hours"
                name="office_hours"
                type="text"
                className="validate"
                value={user.office_hours || ''}
                onChange={(e) => setUser({ ...user, office_hours: e.target.value })}
              />
              <label htmlFor="office_hours">Working Hours</label>
            </div>
          </div>

          <h5 className="mb2">Other</h5>
          {/* Inactive Patients Timeout field */}
          <div className="row">
            <div className="input-field col s12 m6">
              <input
                id="inactive_patients_timeout"
                name="inactive_patients_timeout"
                type="number"
                className="validate"
                value={user.inactive_patients_timeout || ''}
                onChange={(e) => setUser({ ...user, inactive_patients_timeout: e.target.value })}
              />
              <label htmlFor="inactive_patients_timeout">Inactive Patients Timeout</label>
            </div>
          </div>

          <button type="submit" className="btn black">Save</button>
        </form>
      </div>
    </div>
  );
}

export default GeneralSettingsForm;
