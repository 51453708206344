import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../Components/Table';
import LongContent from '../../Components/LongContent';

const CarePlanPDFView = ({ patient, notes, actions, withoutTitlePage = false }) => {
  const NoteContent = ({ row: { original: cell } }) => (
    <LongContent content={cell.content} />
  );

  NoteContent.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        content: PropTypes.string.isRequired,
      }),
    }).isRequired,
  };
  const CreatedAt = ({ row: { original: cell } }) => (
    <div>
      {moment.utc(cell.created_at).local().format('LLL')}
    </div>
  );

  CreatedAt.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.number.isRequired,
        created_at: PropTypes.string.isRequired,
      }),
    }).isRequired,
  };

  const noteColumns = [
    {
      Header: 'Title',
      accessor: 'title',
    },
    {
      Header: 'Note',
      accessor: 'content',
      Cell: NoteContent,
    },
    {
      Header: 'Created On',
      Cell: CreatedAt,
      accessor: 'created_at',
    },
  ];

  const StatusActions = ({ row: { original: cell } }) => (
    <div>
      <label>
        <input type="checkbox" defaultChecked={cell.status === 2} />
        <span />
      </label>
    </div>
  );

  StatusActions.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.number,
        status: PropTypes.number,
      }),
    }).isRequired,
  };

  const ActionContent = ({ row: { original: cell } }) => (
    <LongContent content={cell.action} />
  );

  ActionContent.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.number.isRequired,
        action: PropTypes.string.isRequired,
        status: PropTypes.number.isRequired,
      }),
    }).isRequired,
  };

  const actionColumns = [
    {
      Header: 'Action',
      Cell: ActionContent,
      accessor: 'content',
    },
    {
      Header: 'Assignee',
      accessor: 'assignee',
    },
    {
      Header: 'Created On',
      Cell: CreatedAt,
      accessor: 'created_at',
    },
    {
      Header: 'Status',
      Cell: StatusActions,
      accessor: 'status',
    },
  ];

  return (
    <div className="A4">
      {!withoutTitlePage && (
        <div className="sheet padding-15mm">
          <div className="center">
            <img
              src="/images/logo.png"
              alt="Logo"
              style={{ maxWidth: '300px' }}
            />
          </div>

          <h5 className="pt4 center">
            <b>Patient: </b>
            {`${patient.patient.first_name} ${patient.patient.last_name || ''}`}
          </h5>

          <h6 className="pt4 center">
            <b>CARE PLAN</b>
          </h6>
        </div>
      )}

      {notes && (
        <div className="sheet padding-15mm">
          <h6 className="center">Notes</h6>
          <Table
            columns={noteColumns}
            data={notes}
            centered
            pagination={false}
          />
        </div>
      )}
      {actions && (
        <div className="sheet padding-15mm">
          <h6 className="center">Actions</h6>
          <Table
            columns={actionColumns}
            data={actions}
            centered
            pagination={false}
          />
        </div>
      )}
    </div>
  );
};

CarePlanPDFView.propTypes = {
  patient: PropTypes.shape({
    patient: PropTypes.shape({
      id: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  notes: PropTypes.arrayOf(PropTypes.object).isRequired,
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  withoutTitlePage: PropTypes.bool,
};

CarePlanPDFView.defaultProps = {
  withoutTitlePage: false,
};

export default CarePlanPDFView;
