import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import moment from 'moment';
import { reducer, sendRequest } from '../../../utils';
import Loader from '../../../Components/Loader';

const TYPES = {
  1: 'BloodPressure',
  2: 'PulseOximeter',
  3: 'Weight',
  4: 'BloodGlucose',
  5: 'ECG',
  // 6: 'Medication',
  // 7: 'Steps',
  // 8: 'Calories',
  // 9: 'Water',
  // 10: 'PainLevel',
  // 11: 'Sleep',
  12: 'BodyTemperature',
};

const Reminders = ({ patientId }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isError: false, isLoading: false,
    },
  );

  const handleDeleteReminder = (key) => {
    dispatch({
      type: 'FETCH_SUCCESS',
      payload: Object.fromEntries(Object.entries(state.data).filter(([k]) => k !== key)),
    });
  };

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest(`connections/patients/${patientId}/reminders`, 'GET')
      .then((response) => {
        if (response.status === 'success') {
          const rawReminders = response.data;

          // Group reminders by entry_type, reminder_days and reminder_status joining them
          // to the single with list of reminder_time
          const reminders = rawReminders.reduce((acc, reminder) => {
            const key = `${reminder.entry_type}_${reminder.reminder_days}_${reminder.reminder_status}`;

            if (!acc[key]) {
              acc[key] = {
                ...reminder,
                patientId,
                id: reminder.id,
                ids: [reminder.id],
                reminder_times: [reminder.reminder_time],
              };
            } else {
              acc[key].id = `${acc[key].id}-${reminder.id}`; // `1-2-3
              acc[key].ids.push(reminder.id);
              acc[key].reminder_times.push(reminder.reminder_time);
            }

            return acc;
          }, {});

          // Sort each group by reminder_time
          Object.keys(reminders).forEach((key) => {
            reminders[key].reminder_times.sort((a, b) => {
              const aTime = new Date(`01/01/2024 ${a}`);
              const bTime = new Date(`01/01/2024 ${b}`);

              return aTime - bTime;
            });
          });

          dispatch({ type: 'FETCH_SUCCESS', payload: reminders });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong 123...' });
      });
  }, []);

  if (state.isLoading) {
    return (
      <>
        <h5 className="header mt4">
          Reminders
        </h5>
        <Loader />
      </>
    );
  }

  return (
    <div>
      <h5 className="header-with-button mt4 mb4">
        Reminders
        <Link to={`/patients/${patientId}/reminders`} className="btn btn-small black right">Add</Link>
      </h5>

      {
        Object.keys(state.data).length
          ? (
            <>
              {
                Object.keys(state.data).map((key) => (
                  <Reminder
                    key={key}
                    reminder={state.data[key]}
                    handleDeleteReminder={handleDeleteReminder}
                  />
                ))
              }
            </>
          )
          : <p>No reminders found</p>
      }
    </div>
  );
};

Reminders.propTypes = {
  patientId: PropTypes.string.isRequired,
};

const Reminder = ({ reminder, handleDeleteReminder }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: reminder, message: '', error: '', isLoading: false, isError: false,
    },
  );

  const onDeleteReminder = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    Promise.all(state.data.ids.map((id) => sendRequest(`connections/patients/${state.data.patientId}/reminders/${id}/`, 'DELETE')))
      .then((responses) => {
        const errors = responses.filter((r) => r.status === 'error').map((r) => r.message);
        if (errors.length) {
          dispatch({ type: 'FETCH_FAILURE', error: errors.join(', ') });
        } else {
          dispatch({ type: 'FETCH_SUCCESS', message: 'Reminder deleted successfully' });
          handleDeleteReminder(`${state.data.entry_type}_${state.data.reminder_days}`);
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong 321...' });
      });
  };

  const handleReminderStatusChange = (e) => {
    Promise.all(state.data.ids.map((id, i) => {
      const opts = {
        entry_type: state.data.entry_type,
        reminder_days: state.data.reminder_days,
        reminder_time: state.data.reminder_times[i],
        reminder_notes: state.data.reminder_notes,
        reminder_status: e.target.checked ? 1 : 0,
      };
      return sendRequest(`connections/patients/${state.data.patientId}/reminders/${id}`, 'PUT', opts);
    }))
      .then((responses) => {
        const errors = responses.filter((r) => r.status !== 'success').map((r) => r.message);

        if (errors.length) {
          dispatch({ type: 'FETCH_FAILURE', error: errors.join(', ') });
        } else {
          dispatch({
            type: 'FETCH_SUCCESS',
            payload: { ...state.data, reminder_status: e.target.checked ? 'Active' : 'Inactive' },
          });
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  };

  if (state.isLoading) {
    return (
      <Loader />
    );
  }

  if (state.message) {
    return (
      <div className="form-messages">{state.message}</div>
    );
  }

  return (
    <>
      <h6 className="header-with-button">
        <b>
          {
            state.data.reminder_times.map((t) => moment.utc(t, 'h:mm a').local().format('h:mm a')).join(', ')
          }
        </b>

        <button
          type="button"
          className="btn btn-small red white-text right"
          onClick={onDeleteReminder}
        >
          Delete
        </button>
        <Link
          to={`/patients/${state.data.patientId}/reminders/${state.data.id}`}
          className="btn btn-small black white-text right mr2"
        >
          Edit
        </Link>
      </h6>

      <div className={`form-errors ${state.error ? '' : 'hide'}`}>{state.error}</div>

      <div>
        <b>{TYPES[state.data.entry_type]}</b>
        <br />
        {state.data.reminder_days}
        <br />

        <small>{state.data.reminder_notes}</small>
        <br />

        <div className="switch">
          <label>
            Off
            <input
              type="checkbox"
              defaultChecked={state.data.reminder_status === 1}
              onChange={handleReminderStatusChange}
            />
            <span className="lever" />
            On
          </label>
        </div>
      </div>
    </>
  );
};

Reminder.propTypes = {
  reminder: PropTypes.shape({
    ids: PropTypes.arrayOf(PropTypes.number).isRequired,
    reminder_times: PropTypes.arrayOf(PropTypes.string).isRequired,
    entry_type: PropTypes.number.isRequired,
    reminder_days: PropTypes.string.isRequired,
    reminder_notes: PropTypes.string.isRequired,
    reminder_status: PropTypes.oneOf([0, 1]).isRequired,
  }).isRequired,
  handleDeleteReminder: PropTypes.func.isRequired,
};

export default Reminders;
