import React from 'react';

import M from '@materializecss/materialize';
import { useHistory, useParams } from 'react-router-dom';
import { reducer, sendRequest } from '../../../utils';
import Loader from '../../../Components/Loader';
import Select from '../../../Components/Select';
import { ENTRY_TYPES_TO_FIELDS, ENTRY_TYPES_TO_UNITS, INTEGER_ENTRY_TYPES } from './constants';
import { ENTRY_TYPES_LABELS } from '../../Data/constants';

const AddAlertZone = () => {
  const { patientId } = useParams();
  const history = useHistory();

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isError: false, isLoading: false,
    },
  );

  const [type, setType] = React.useState(1);
  const [existingTypes, setExistingTypes] = React.useState([]);

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest(`connections/patients/${patientId}/alert_zones`, 'GET')
      .then((response) => {
        if (response.status === 'success') {
          // Save existing alert zones types to disable them in the select
          setExistingTypes(response.data.map((az) => az.entry_type));

          dispatch({ type: 'FETCH_SUCCESS' });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    const opts = Object.fromEntries(new FormData(e.target));

    opts.entry_type = Number(type);
    opts.field_name = ENTRY_TYPES_TO_FIELDS[opts.entry_type];
    opts.comparison_operator = 'in';

    // Field Group needed to join alert zones
    opts.field_group = (new Date()).getTime();

    // TODO: Refactor this to avoid code duplication
    if (opts.entry_type === 1) {
      // Send data in two requests: systolic and diastolic
      const systolicOpts = {
        entry_type: opts.entry_type,
        field_group: opts.field_group,
        field_name: 'Systolic',
        high_value: opts['high_value-0'],
        low_value: opts['low_value-0'],
        value_unit: ENTRY_TYPES_TO_UNITS[opts.entry_type],
        comparison_operator: 'in',
      };

      const diastolicOpts = {
        entry_type: opts.entry_type,
        field_group: opts.field_group,
        field_name: 'Diastolic',
        high_value: opts['high_value-1'],
        low_value: opts['low_value-1'],
        value_unit: ENTRY_TYPES_TO_UNITS[opts.entry_type],
        comparison_operator: 'in',
      };

      Promise.all([
        sendRequest(`connections/patients/${patientId}/alert_zones`, 'POST', systolicOpts),
        sendRequest(`connections/patients/${patientId}/alert_zones`, 'POST', diastolicOpts),
      ])
        .then(([r1, r2]) => {
          if (r1.status === 'success' && r2.status === 'success') {
            if (e.nativeEvent.submitter.value === 'submit-and-go-back') {
              history.goBack();
            }
            dispatch({ type: 'FETCH_SUCCESS', message: r1.message });

            // Do not allow to add another alert zone with the same type
            setExistingTypes([...existingTypes, opts.entry_type]);
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: r1.message || r2.message });
          }
        })
        .catch(() => dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' }));
    } else if (opts.entry_type === 2) {
      // Send data in two requests: systolic and diastolic
      const pulseOpts = {
        entry_type: opts.entry_type,
        field_group: opts.field_group,
        field_name: 'Pulse',
        high_value: opts['high_value-0'],
        low_value: opts['low_value-0'],
        value_unit: 'bpm',
        comparison_operator: 'in',
      };

      const spo2Opts = {
        entry_type: opts.entry_type,
        field_group: opts.field_group,
        field_name: 'Spo2',
        high_value: opts['high_value-1'],
        low_value: opts['low_value-1'],
        value_unit: '%SpO2',
        comparison_operator: 'in',
      };

      Promise.all([
        sendRequest(`connections/patients/${patientId}/alert_zones`, 'POST', pulseOpts),
        sendRequest(`connections/patients/${patientId}/alert_zones`, 'POST', spo2Opts),
      ])
        .then(([r1, r2]) => {
          if (r1.status === 'success' && r2.status === 'success') {
            if (e.nativeEvent.submitter.value === 'submit-and-go-back') {
              history.goBack();
            }
            dispatch({ type: 'FETCH_SUCCESS', message: r1.message });

            // Do not allow to add another alert zone with the same type
            setExistingTypes([...existingTypes, opts.entry_type]);
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: r1.message || r2.message });
          }
        })
        .catch(() => dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' }));
    } else {
      sendRequest(`connections/patients/${patientId}/alert_zones`, 'POST', opts)
        .then((response) => {
          if (response.status === 'success') {
            if (e.nativeEvent.submitter.value === 'submit-and-go-back') {
              history.goBack();
            }
            dispatch({ type: 'FETCH_SUCCESS', message: response.message });

            // Do not allow to add another alert zone with the same type
            setExistingTypes([...existingTypes, opts.entry_type]);
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: response.message });
          }
        })
        .catch(() => dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' }));
    }
  };

  React.useEffect(() => {
    M.AutoInit();
    M.updateTextFields();
  });

  React.useEffect(() => {
    // Hack to update units element
    setTimeout(M.AutoInit, 100);
    M.updateTextFields();
  }, [type]);

  // The two function below needed to disable decimals for specific types of alert zones
  // For now, it will remove decimals for BPM and Pulse
  const onKeyDown = (event) => {
    if (INTEGER_ENTRY_TYPES.includes(type) && ['.', ','].includes(event.key)) {
      event.preventDefault();
    }
  };

  const onInput = (event) => {
    if (INTEGER_ENTRY_TYPES.includes(type)) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = event.target.value.replace(/[^0-9]*/g, '');
    }
  };

  // We do not want to allow to create alert zones for the same type twice
  const getAllowedTypes = () => Object.keys(ENTRY_TYPES_LABELS)
    .filter((key) => !existingTypes.includes(Number(key)) && ![6, 7, 8, 9, 10, 11].includes(Number(key)))
    .reduce((obj, key) => {
      obj[key] = ENTRY_TYPES_LABELS[key];
      return obj;
    }, {});

  const allowedTypes = getAllowedTypes();

  if (!Object.keys(allowedTypes).length) {
    return (
      <div className="container mt4 mb4">
        <h5 className="header mt4">
          New Alert zone
        </h5>

        <h6 className="">
          You have already created all the alert zones for this patient.
        </h6>

        <button
          type="button"
          value="close"
          className="btn white black-text mt2"
          onClick={() => history.goBack()}
        >
          Close
        </button>
      </div>
    );
  }

  return (
    <div className="container mt4 mb4">
      <h5 className="header mt4">
        New Alert zone
      </h5>

      {
          state.isLoading
            ? <Loader />
            : (
              <div className="row">
                <form className="col s12" onSubmit={handleSubmit}>
                  <div className={`form-messages ${state.message ? '' : 'hide'}`}>{state.message}</div>
                  <div className={`form-errors ${state.isError ? '' : 'hide'}`}>{state.error}</div>

                  <div className="row">
                    <div className="input-field col s12 m6">
                      <Select
                        name="entry_type"
                        label="Type"
                        values={allowedTypes}
                        defaultValue={type}
                        onChange={(e) => setType(Number(e.target.value))}
                      />
                      <small>Only one alert zone for each vital can be created. Please, go back to alert zones list to see other vitals.</small>
                    </div>
                    <div className="input-field col s12 m6">
                      <input
                        id="value_unit"
                        name="value_unit"
                        type="text"
                        className="validate"
                        value={ENTRY_TYPES_TO_UNITS[type]}
                        readOnly
                      />
                      <label htmlFor="value_unit">Unit</label>
                    </div>
                  </div>

                  {
                    type === 1 && (
                    <>
                      <h6>Systolic</h6>
                      <div className="row">
                        <div className="input-field col s12 m6">
                          <label htmlFor="high_value-0">Max. value</label>
                          <input
                            id="high_value-0"
                            name="high_value-0"
                            type="number"
                            className="validate"
                            step={INTEGER_ENTRY_TYPES.includes(type) ? 1 : 0.1}
                            onKeyDown={onKeyDown}
                            onInput={onInput}
                            required
                          />
                        </div>

                        <div className="input-field col s12 m6">
                          <label htmlFor="low_value-0">Min. value</label>
                          <input
                            id="low_value-0"
                            name="low_value-0"
                            type="number"
                            className="validate"
                            step={INTEGER_ENTRY_TYPES.includes(type) ? 1 : 0.1}
                            onKeyDown={onKeyDown}
                            onInput={onInput}
                            required
                          />
                        </div>
                      </div>

                      <h6>Diastolic</h6>
                      <div className="row">
                        <div className="input-field col s12 m6">
                          <label htmlFor="high_value-1">Max. value</label>
                          <input
                            id="high_value-1"
                            name="high_value-1"
                            type="number"
                            className="validate"
                            step={INTEGER_ENTRY_TYPES.includes(type) ? 1 : 0.1}
                            onKeyDown={onKeyDown}
                            onInput={onInput}
                            required
                          />
                        </div>

                        <div className="input-field col s12 m6">
                          <label htmlFor="low_value-1">Min. value</label>
                          <input
                            id="low_value-1"
                            name="low_value-1"
                            type="number"
                            className="validate"
                            step={INTEGER_ENTRY_TYPES.includes(type) ? 1 : 0.1}
                            onKeyDown={onKeyDown}
                            onInput={onInput}
                            required
                          />
                        </div>
                      </div>
                    </>
                    )
                  }

                  {
                    type === 2 && (
                    <>
                      <h6>Pulse</h6>
                      <div className="row">
                        <div className="input-field col s12 m6">
                          <label htmlFor="high_value-0">Max. value</label>
                          <input
                            id="high_value-0"
                            name="high_value-0"
                            type="number"
                            className="validate"
                            step={INTEGER_ENTRY_TYPES.includes(type) ? 1 : 0.1}
                            onKeyDown={onKeyDown}
                            onInput={onInput}
                            required
                          />
                        </div>

                        <div className="input-field col s12 m6">
                          <label htmlFor="low_value-0">Min. value</label>
                          <input
                            id="low_value-0"
                            name="low_value-0"
                            type="number"
                            className="validate"
                            step={INTEGER_ENTRY_TYPES.includes(type) ? 1 : 0.1}
                            onKeyDown={onKeyDown}
                            onInput={onInput}
                            required
                          />
                        </div>
                      </div>

                      <h6>SpO2</h6>
                      <div className="row">
                        <div className="input-field col s12 m6">
                          <label htmlFor="high_value-1">Max. value</label>
                          <input
                            id="high_value-1"
                            name="high_value-1"
                            type="number"
                            className="validate"
                            step={INTEGER_ENTRY_TYPES.includes(type) ? 1 : 0.1}
                            onKeyDown={onKeyDown}
                            onInput={onInput}
                            required
                          />
                        </div>

                        <div className="input-field col s12 m6">
                          <label htmlFor="low_value-1">Min. value</label>
                          <input
                            id="low_value-1"
                            name="low_value-1"
                            type="number"
                            className="validate"
                            step={INTEGER_ENTRY_TYPES.includes(type) ? 1 : 0.1}
                            onKeyDown={onKeyDown}
                            onInput={onInput}
                            required
                          />
                        </div>
                      </div>
                    </>
                    )
                  }

                  {
                    type !== 1 && type !== 2 && (
                    <>
                      <div className="row">
                        <div className="input-field col s12 m6">
                          <label htmlFor="high_value">Max. value</label>
                          <input
                            id="high_value"
                            name="high_value"
                            type="number"
                            className="validate"
                            step={INTEGER_ENTRY_TYPES.includes(type) ? 1 : 0.1}
                            onKeyDown={onKeyDown}
                            onInput={onInput}
                            required
                          />
                        </div>

                        <div className="input-field col s12 m6">
                          <label htmlFor="low_value">Min. value</label>
                          <input
                            id="low_value"
                            name="low_value"
                            type="number"
                            className="validate"
                            step={INTEGER_ENTRY_TYPES.includes(type) ? 1 : 0.1}
                            onKeyDown={onKeyDown}
                            onInput={onInput}
                            required
                          />
                        </div>
                      </div>
                    </>
                    )
                  }

                  <button type="submit" value="submit" className="btn black mt2">Save</button>
                  <button type="submit" value="submit-and-go-back" className="btn black mt2 ml2">Save And Close</button>
                  <button
                    type="button"
                    value="close"
                    className="btn white black-text mt2 ml2"
                    onClick={() => history.goBack()}
                  >
                    Close
                  </button>
                </form>
              </div>
            )
        }
    </div>
  );
};

AddAlertZone.propTypes = {};

export default AddAlertZone;
