import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { SALES_REPS } from './constants';
import RangeButtonsGroup from '../Patients/DetailsButtons';
import { reducer, sendRequest } from '../utils';
import Table from '../Components/Table';

const RepStatsTable = ({ periodData }) => {
  // Number of doctors for each rep (Fred, Dani, Sam) and the number of patients their doctors have
  const numberOfDoctorsPerRep = periodData.reduce((acc, d) => {
    const rep = d.sales_info?.sales_rep ? Object.fromEntries(SALES_REPS)[d.sales_info?.sales_rep] : 'No rep';

    if (!acc[rep]) {
      acc[rep] = { numberOfDoctors: 0, numberOfPatients: 0 };
    }
    acc[rep].numberOfDoctors += 1;
    acc[rep].numberOfPatients += d.number_of_patients;
    return acc;
  }, {});

  const data = Object.keys(numberOfDoctorsPerRep).map((rep) => ({
    rep: rep || 'No rep',
    number_of_doctors: numberOfDoctorsPerRep[rep].numberOfDoctors,
    number_of_patients: numberOfDoctorsPerRep[rep].numberOfPatients,
  }));

  const columns = [
    {
      Header: 'Rep',
      accessor: 'rep',
    },
    {
      Header: 'Number of Doctors',
      accessor: 'number_of_doctors',
    },
    {
      Header: 'Number of Patients',
      accessor: 'number_of_patients',
    },
  ];

  return (
    <div className="row">
      <Table
        columns={columns}
        data={data}
        centered
      />
    </div>
  );
};

RepStatsTable.propTypes = {
  periodData: PropTypes.arrayOf(PropTypes.shape({
    sales_rep: PropTypes.string.isRequired,
    number_of_patients: PropTypes.number.isRequired,
  })).isRequired,
};

const AnalyticsDashboard = () => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], isLoading: true, isError: false },
  );

  const [startDate, setStartDate] = React.useState(moment().subtract(1, 'months').toDate());
  const [endDate, setEndDate] = React.useState(moment().toDate());

  const handleIntervalChanges = ({ startDate, endDate }) => {
    if (startDate) setStartDate(startDate);
    if (endDate) setEndDate(endDate);
  };

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('admin/doctors', 'GET')
      .then((response) => {
        dispatch({
          type: 'FETCH_SUCCESS',
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error });
      });
  }, []);

  // Calculate report data
  const periodData = state.data.filter((d) => {
    const createdDate = moment.utc(d.doctor_profile.created_at).local();
    return createdDate.isBetween(startDate, endDate);
  });

  // Number of doctors enrolled
  const numberOfDoctors = periodData.length;

  // Total number of patients enrolled
  const numberOfPatients = periodData.reduce((acc, d) => acc + d.number_of_patients, 0);

  // Average number of patients enrolled
  const averageNumberOfPatients = Math.round(numberOfPatients / numberOfDoctors, 2) || 0;

  // Doctors in the free trial stage
  const numberOfDoctorsInFreeTrial = periodData.filter((d) => d.sales_info?.status === 'free_trial').length;

  return (
    <div className="container mt4 mb4">
      <a href="/admin/" className="btn btn-small white black-text">
        <i className="material-icons left">arrow_back</i>
        Go Back to Admin Dashboard
      </a>

      <h4>Analytics</h4>

      <RangeButtonsGroup
        defaultInterval="monthly"
        // startDate={startDate}
        // endDate={endDate}
        callback={handleIntervalChanges}
      />

      <div className="row">
        <div className="col s6 m6">
          <div className="card teal">
            <div className="card-content white-text">
              <span className="card-title text-center">Number of doctors enrolled</span>
              <div className="center-align">
                <h4>{numberOfDoctors}</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col s6 m6">
          <div className="card teal">
            <div className="card-content white-text">
              <span className="card-title text-center">Number of patients enrolled</span>
              <div className="center-align">
                <h4>{numberOfPatients}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col s6 m6">
          <div className="card teal">
            <div className="card-content white-text">
              <span className="card-title text-center">Average number of patients enrolled</span>
              <div className="center-align">
                <h4>{averageNumberOfPatients}</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col s6 m6">
          <div className="card teal">
            <div className="card-content white-text">
              <span className="card-title text-center">Doctors in the free trial stage</span>
              <div className="center-align">
                <h4>{numberOfDoctorsInFreeTrial}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <RepStatsTable periodData={periodData} />

    </div>

  );
};

export default AnalyticsDashboard;
