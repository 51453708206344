import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import Loader from '../Components/Loader';
import { reducer, sendRequest } from '../utils';
import Table from '../Components/Table';

const WhatsNew = () => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: true, isError: false,
    },
  );

  const handleFetchChangelogs = React.useCallback(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('change_logs', 'GET')
      .then((response) => {
        if (response.status === 'Failure') {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
          return;
        }

        // Preprocess requests
        const changelogs = response.data.map(
          (l) => ({
            id: l.id,
            version: l.version,
            date_released: moment(new Date(l.date_released)).format('MMMM DD, YYYY'),
            change_notes: l.change_notes,
            module: l.module,
          }),
        );

        dispatch({
          type: 'FETCH_SUCCESS',
          payload: changelogs,
        });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  }, []);

  React.useEffect(() => {
    handleFetchChangelogs();
  }, [handleFetchChangelogs]);

  return (
    <div className="container mt4 mb4">
      <h4>What&apos;s New</h4>
      <div className="row">
        <div className="col s12">
          {state.isError && <p className="form-errors">{state.error}</p>}
          {state.message && <p className="form-messages">{state.message}</p>}

          {state.isLoading ? (
            <Loader />
          ) : (
            <>
              {state.data.length
                ? (
                  <ChangelogsTable
                    data={state.data}
                  />
                )
                : <p>There are no updates.</p>}

            </>
          )}
        </div>
      </div>
    </div>
  );
};

const ChangelogsTable = ({ data }) => {
  // Wrap change_notes with <pre> tag to preserve newlines
  const ChangeNotesCell = ({ value }) => (
    <pre>{value}</pre>
  );
  ChangeNotesCell.propTypes = {
    value: PropTypes.string.isRequired,
  };

  const columns = [
    {
      Header: 'Version',
      accessor: 'version',
    },
    {
      Header: 'Date Released',
      accessor: 'date_released',
    },
    {
      Header: 'Change Notes',
      accessor: 'change_notes',
      Cell: ChangeNotesCell,
    },
    {
      Header: 'Module',
      accessor: 'module',
    },
  ];

  return (
    <Table columns={columns} data={data} centered />
  );
};

ChangelogsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      version: PropTypes.string.isRequired,
      date_released: PropTypes.string.isRequired,
      change_notes: PropTypes.string.isRequired,
      module: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default WhatsNew;
