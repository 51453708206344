import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const DecimalInput = ({ defaultValue = '', label, name, onValueChange }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (defaultValue) {
      const formattedDefaultValue = parseFloat(defaultValue).toFixed(2);
      setValue(formattedDefaultValue);
    }
  }, [defaultValue]);

  const handleChange = (e) => {
    const inputValue = e.target.value.replace(',', '.');

    // Ensure the input value is a valid number with up to two decimals
    const regex = /^(\d+(\.\d{0,2})?)$/;
    if (regex.test(inputValue) || inputValue === '') {
      setValue(inputValue);
    }
  };

  const handleBlur = () => {
    if (value) {
      // Ensure the value has two decimal places and convert to valid float
      const formattedValue = parseFloat(value).toFixed(2);
      setValue(formattedValue);
      if (onValueChange) {
        onValueChange(parseFloat(formattedValue));
      }
    }
  };

  return (
    <div className="input-field col s12 m6">
      {label && <label htmlFor={name}>{label}</label>}
      <input
        type="text"
        id={name}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Enter a number"
      />
    </div>
  );
};

DecimalInput.propTypes = {
  defaultValue: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
};

DecimalInput.defaultProps = {
  defaultValue: 0,
  name: '',
  label: '',
};

export default DecimalInput;
