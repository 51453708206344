import M from '@materializecss/materialize';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const Select = ({
  name, label, values, defaultValue, onChange, multiple, required,
}) => {
  const [id] = React.useState(() => _.uniqueId(`${name}-`));

  const selectValues = Array.isArray(values) ? values : Object.entries(values);

  React.useEffect(() => {
    // if (!multiple && document.querySelector(`#${id}`).M_FormSelect) {
    //   document.querySelector(`#${id}`).value = defaultValue;
    // }

    M.FormSelect.init(document.querySelector(`#${id}`));
  }, [values]);

  React.useEffect(() => {
    if (!multiple && document.querySelector(`#${id}`).M_FormSelect) {
      document.querySelector(`#${id}`).value = defaultValue;
      M.FormSelect.init(document.querySelector(`#${id}`));
    }
  }, [defaultValue]);

  if (multiple) {
    return (
      <>
        <select
          id={id}
          name={name}
          onChange={onChange}
          multiple={multiple}
          required={required}
        >
          {
            selectValues.map(
              (value) => (
                <option
                  key={value[0]}
                  value={value[0]}
                  selected={defaultValue.includes(value[0])}
                >
                  {value[1]}
                </option>
              ),
            )
          }
        </select>
        <label htmlFor={id}>{label}</label>
      </>
    );
  }

  return (
    <>
      <select
        id={id}
        name={name}
        defaultValue={defaultValue}
        onChange={onChange}
        multiple={multiple}
        required={required}
      >
        {
          selectValues.map(
            (value) => (
              <option
                key={value[0]}
                value={value[0]}
              >
                {value[1]}
              </option>
            ),
          )
        }
      </select>
      <label htmlFor={id}>{label}</label>
    </>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
};

Select.defaultProps = {
  defaultValue: '',
  onChange: () => { },
  multiple: false,
  required: false,
};

export default Select;
