import M from '@materializecss/materialize';
import React from 'react';
import { useHistory } from 'react-router';
import { PropTypes } from 'prop-types';

import Loader from '../Components/Loader';
import { reducer, sendRequest } from '../utils';

const SearchOrAddColleague = () => {
  const [search, setSearch] = React.useState(false);

  const handleSearhOrAddToggle = () => {
    setSearch(!search);
  };

  return (
    <div className="container mt4 mb4">
      <h4 className="mt4 mb4">Add a new colleague</h4>

      <div className="switch hide">
        <label>
          My colleague is already registered
          <input type="checkbox" defaultChecked={search} onChange={handleSearhOrAddToggle} />
          <span className="lever" />
        </label>
      </div>

      {
        search
          ? <SearchForColleagues />
          : <AddColleague />
      }
    </div>
  );
};

const AddColleague = () => {
  const history = useHistory();

  const [email, setEmail] = React.useState('');
  const [confirmEmail, setConfirmEmail] = React.useState('');

  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [jobTitle, setJobTitle] = React.useState('');

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: false, isError: false,
    },
  );

  const onSubmitClick = async (e) => {
    e.preventDefault();
    dispatch({ type: 'FETCH_INIT' });

    if (email !== confirmEmail) {
      dispatch({ type: 'FETCH_FAILURE', error: 'Emails do not match' });
      return;
    }

    if (password !== confirmPassword) {
      dispatch({ type: 'FETCH_FAILURE', error: 'Passwords do not match' });
      return;
    }

    const opts = {
      email,
      first_name: firstName,
      last_name: lastName,
      job_title: jobTitle,
      password,
    };

    sendRequest('connections/colleagues', 'POST', opts)
      .then((response) => {
        if (response.status === 'error') {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        } else {
          // Reset form
          setEmail('');
          setConfirmEmail('');

          setPassword('');
          setConfirmPassword('');

          setFirstName('');
          setLastName('');

          dispatch({ type: 'FETCH_SUCCESS', message: response.message || 'Colleague added' });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: error.message });
      });
  };

  const onCancelClick = (e) => {
    e.preventDefault();
    history.goBack();
  };

  React.useEffect(() => {
    M.updateTextFields();
  });

  return (
    <>
      <h5 className="mt4 mb4">Create an account for a new colleague</h5>
      {
        state.isLoading
          ? <Loader />
          : (
            <>
              <div className="row">
                <form className="col s12" onSubmit={onSubmitClick}>
                  <div className={`form-messages ${state.message ? '' : 'hide'}`}>{state.message}</div>
                  <div className={`form-errors ${state.error ? '' : 'hide'}`}>{state.error}</div>

                  <div className="row">
                    <div className="input-field col s4">
                      <label htmlFor="email">First Name</label>
                      <input
                        id="first_name"
                        name="first_name"
                        type="text"
                        className="validate"
                        defaultValue={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </div>

                    <div className="input-field col s4">
                      <label htmlFor="last_name">Last Name</label>
                      <input
                        id="last_name"
                        name="last_name"
                        type="text"
                        className="validate"
                        defaultValue={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </div>

                    <div className="input-field col s4">
                      <label htmlFor="job_title">Job Title</label>
                      <input
                        id="job_title"
                        name="job_title"
                        type="text"
                        className="validate"
                        defaultValue={jobTitle}
                        onChange={(e) => setJobTitle(e.target.value)}
                        required
                      />
                    </div>

                    <div className="row">
                      <div className="input-field col s6">
                        <label htmlFor="email">Colleague&apos;s Email</label>
                        <input
                          id="email"
                          name="email"
                          type="email"
                          className="validate"
                          defaultValue={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>

                      <div className="input-field col s6">
                        <label htmlFor="email">Confirm Colleague&apos;s Email</label>
                        <input
                          id="confirm_email"
                          name="confirm_email"
                          type="email"
                          className={`validate ${email === confirmEmail ? '' : 'invalid'}`}
                          defaultValue={confirmEmail}
                          onChange={(e) => setConfirmEmail(e.target.value)}
                          // Do not allow to copy paste
                          onPaste={(e) => e.preventDefault()}
                          required
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="input-field col s6">
                        <label htmlFor="password">Colleague&apos;s Password</label>
                        <input
                          id="password"
                          name="password"
                          type="password"
                          className="validate"
                          defaultValue={email}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </div>

                      <div className="input-field col s6">
                        <label htmlFor="confirm_password">Confirm Colleague&apos;s Password</label>
                        <input
                          id="confirm_password"
                          name="confirm_password"
                          type="password"
                          className={`validate ${password === confirmPassword ? '' : 'invalid'}`}
                          defaultValue={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          // Do not allow to copy paste
                          // onPaste={(e) => e.preventDefault()}
                          required
                        />
                      </div>
                    </div>

                  </div>
                  <button type="submit" className="btn black mt2">Create New Account</button>
                  <button type="button" className="btn white black-text ml2 mt2" onClick={onCancelClick}>Go Back</button>
                </form>
              </div>

              {
                state.data.length > 0 && (
                <div className="mt4">
                  <h6 className="mb2">Found Doctors / Nurses:</h6>
                  {state.data.map((u) => <PotentialColleague key={u.id} user={u} />)}
                </div>
                )
                }
            </>
          )
      }
    </>
  );
};

const SearchForColleagues = () => {
  const history = useHistory();

  const [email, setEmail] = React.useState('');

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: false, isError: false,
    },
  );

  const onSubmitClick = async (e) => {
    e.preventDefault();
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('search_physician_by_email', 'POST', { email })
      .then((response) => {
        const { users } = response;

        if (users.length) {
          dispatch({ type: 'FETCH_SUCCESS', payload: users });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: 'No users found', payload: [] });
        }
      });
  };

  const onCancelClick = (e) => {
    e.preventDefault();
    history.goBack();
  };

  React.useEffect(() => {
    M.updateTextFields();
  });

  return (
    <>
      <h5 className="mt4 mb4">Search for colleagues</h5>
      {
        state.isLoading
          ? <Loader />
          : (
            <>
              <div className="row">
                <form className="col s12">
                  <div className={`form-messages ${state.message ? '' : 'hide'}`}>{state.message}</div>
                  <div className={`form-errors ${state.error ? '' : 'hide'}`}>{state.error}</div>
                  <div className="row">
                    <div className="input-field col s12">
                      <label htmlFor="email">Email</label>
                      <input
                        id="email"
                        type="email"
                        className="validate"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </div>
                  </div>
                  <button type="submit" className="btn black mt2" onClick={onSubmitClick}>Search</button>
                  <button type="button" className="btn white black-text ml2 mt2" onClick={onCancelClick}>Go Back</button>
                </form>
              </div>

              {
                state.data.length > 0 && (
                <div className="mt4">
                  <h6 className="mb2">Found Doctors / Nurses:</h6>
                  {state.data.map((u) => <PotentialColleague key={u.id} user={u} />)}
                </div>
                )
                }
            </>
          )
      }
    </>
  );
};

const PotentialColleague = ({ user }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: false, isError: false,
    },
  );

  const onAddColleague = (e) => {
    e.preventDefault();
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('create_colleague_connection', 'POST', { colleague_id: Number(user.id) })
      .then((response) => {
        if (response.status === 'success') {
          dispatch({ type: 'FETCH_SUCCESS', message: response.message });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      });
  };

  if (state.message || state.error) {
    return (
      <div className="row">
        <div className="col s6" style={{ lineHeight: '36px', height: '36px' }}>{`${user.first_name} ${user.last_name || ''}`}</div>
        <div className="col s6 text-center" style={{ lineHeight: '36px', height: '36px' }}>
          <strong>{state.message || state.error}</strong>
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      {
      state.isLoading
        ? <Loader />
        : (
          <>
            <div className="col s10" style={{ lineHeight: '36px', height: '36px' }}>
              {`${user.first_name} ${user.last_name || ''} (${user.email})`}
            </div>
            <div className="col s2">
              <button type="submit" className="btn black" onClick={onAddColleague}>Add</button>
            </div>
          </>
        )
    }
    </div>
  );
};

PotentialColleague.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};

export default SearchOrAddColleague;
