import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import BloodPressurePlot from './Data/BloodPressure';
import PulsePlot from './Data/Pulse';
import WeightPlot from './Data/Weight';
import ECGPlot from './Data/ECG';
import TemperaturePlot from './Data/Temperature';
import GlucosePlot from './Data/Glucose';
import PatientEntries from './Data/Entries';
import SpentTimeSummary from './Summary/SpentTimeSummary';
import { sendRequest } from '../utils';
import CarePlanPDFView from './CarePlan/CarePlanPDFView';

const CarePlanPDF = ({ patient, startDate, endDate }) => {
  const [notes, setNotes] = React.useState([]);
  const [actions, setActions] = React.useState([]);

  React.useEffect(() => {
    const notesPromise = sendRequest(`connections/patients/${patient.patient.id}/notes`, 'GET')
      .then((resp) => resp.data);

    const actionsPromise = sendRequest(`connections/patients/${patient.patient.id}/actions`, 'GET')
      .then((resp) => resp.data);

    Promise.all([notesPromise, actionsPromise])
      .then(([notes, actions]) => {
        const filteredNotes = notes
          .filter((note) => {
            const noteDate = moment.utc(note.created_at).local();
            return noteDate.isBetween(startDate, endDate);
          })
          .map((note) => ({
            id: note.id,
            created_at: note.created_at,
            title: note.title,
            content: note.content,
          }));

        const filteredActions = actions.filter((action) => {
          const actionDate = moment.utc(action.created_at).local();
          return actionDate.isBetween(startDate, endDate);
        })
          .map((action) => ({
            id: action.id,
            created_at: action.created_at,
            action: action.action,
            assignee: `${action.assignee?.first_name} ${action.assignee?.last_name}`,
            status: action.status,
          }));

        setNotes(filteredNotes);
        setActions(filteredActions);
      });
  }, [patient, startDate, endDate]);

  return (
    <CarePlanPDFView patient={patient} notes={notes} actions={actions} withoutTitlePage />
  );
};

CarePlanPDF.propTypes = {
  patient: PropTypes.shape({
    patient: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
};

const PatientDetailsPDF = ({
  patient, startDate, endDate, items,
}) => {
  const period = `${moment(startDate).format('l').slice(0, 10)} - ${moment(endDate).format('l').slice(0, 10)}`;

  return (
    <div className="A4">
      <div className="sheet padding-15mm">
        <div className="center">
          <img src="/images/logo.png" alt="Logo" style={{ maxWidth: '300px' }} />
        </div>

        <h5 className="pt4 center">
          <b>Patient: </b>
          {`${patient.patient.first_name} ${patient.patient.last_name || ''}`}
        </h5>

        <h6 className="center">
          <b>Period: </b>
          {moment(startDate).format('l').slice(0, 10)}
          {' - '}
          {moment(endDate).format('l').slice(0, 10)}
        </h6>
      </div>

      {
        items.includes('time_spent')
        && (
          <div className="sheet padding-15mm">
            <h5 className="center">
              Time Spent (
              {period}
              )
            </h5>
            <SpentTimeSummary patientId={patient.patient.id} startDate={startDate} endDate={endDate} />
          </div>
        )
      }

      {
        items.includes('care_plan')
        && (
          <div className="sheet padding-15mm">
            <h5 className="center">
              Care Plan
            </h5>
            <CarePlanPDF patient={patient} startDate={startDate} endDate={endDate} />
          </div>
        )
      }

      {
        items.includes('bpm')
        && (
          <div className="sheet padding-15mm">
            <h5 className="center">
              Blood Pressure (
              {period}
              )
            </h5>
            <BloodPressurePlot patient={patient} startDate={startDate} endDate={endDate} isActive />
          </div>
        )
      }

      {
        items.includes('pulse')
        && (
        <div className="sheet padding-15mm">
          <h5 className="center">
            Pulse (
            {period}
            )
          </h5>
          <PulsePlot patient={patient} startDate={startDate} endDate={endDate} isActive />
        </div>
        )
      }

      {
        items.includes('weight')
        && (
        <div className="sheet padding-15mm">
          <h5 className="center">
            Weight (
            {period}
            )
          </h5>
          <WeightPlot patient={patient} startDate={startDate} endDate={endDate} isActive />
        </div>
        )
      }

      {
        items.includes('ecg')
        && (
        <div className="sheet padding-15mm">
          <h5 className="center">
            ECG (
            {period}
            )
          </h5>
          <ECGPlot patient={patient} startDate={startDate} endDate={endDate} isActive />
        </div>
        )
      }

      {
        items.includes('temperature')
        && (
        <div className="sheet padding-15mm">
          <h5 className="center">
            Temperature (
            {period}
            )
          </h5>
          <TemperaturePlot patient={patient} startDate={startDate} endDate={endDate} isActive />
        </div>
        )
      }

      {
        items.includes('bgm')
        && (
        <div className="sheet padding-15mm">
          <h5 className="center">
            Blood Glucose (
            {period}
            )
          </h5>
          <GlucosePlot patient={patient} startDate={startDate} endDate={endDate} isActive />
        </div>
        )
      }

      {
        items.includes('entries')
        && (
        <div className="sheet padding-15mm">
          <h5 className="center">
            Entries (
            {period}
            )
          </h5>
          <PatientEntries
            patient={patient}
            startDate={startDate}
            endDate={endDate}
            isActive
            withDownloadButton={false}
            withPagination={false}
          />
        </div>
        )
      }
    </div>
  );
};

PatientDetailsPDF.propTypes = {
  patient: PropTypes.shape({
    patient: PropTypes.shape({
      id: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PatientDetailsPDF;
