import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { sendRequest } from '../utils';

const InvitePatient = () => {
  const history = useHistory();

  const [error, setError] = React.useState('');

  const onFormSubmit = (e) => {
    e.preventDefault();

    setError('');

    // Get form content and print it to console
    const data = new FormData(e.target);
    // setData(Object.fromEntries(data));

    const payload = {
      email: data.get('email'),
      first_name: data.get('first_name'),
      last_name: data.get('last_name'),
      patient_profile: {
        date_of_birth: data.get('date_of_birth'),
      },
    };

    sendRequest('connections/patients', 'POST', payload)
      .then((res) => {
        if (res.status === 'success') {
          // Redirect to the patient page
          history.push(`/patients/${res.data.id}`);
        } else {
          // Show error message
          // eslint-disable-next-line no-console
          console.error(res);
          setError(`Something went wrong. More details: ${res.message}`);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setError(`An error occurred. Please try again later. More details: ${err.message}`);
      });
  };

  const onCancelClick = (e) => {
    e.preventDefault();

    // Redirect back
    history.goBack();
  };

  return (
    <div className="container mt4 mb4">
      <h4 className="mb4">Add new patient</h4>

      <div className="mb4">
        Use the form below to add a new patient to the system. The patient will receive an email
        with a link to complete their registration. Also, the patient will be automatically added
        to your list of patients.
      </div>

      <div className="row">
        <form className="col s12" onSubmit={onFormSubmit}>
          <div className={`form-errors ${error ? '' : 'hide'}`}>{error}</div>

          <div className="row">
            <div className="input-field col s6">
              <input
                id="first_name"
                name="first_name"
                type="text"
                placeholder="Patient First Name"
                className="validate"
                required
              />
              <label htmlFor="first_name">First Name</label>
            </div>

            <div className="input-field col s6">
              <input
                id="last_name"
                name="last_name"
                type="text"
                placeholder="Patient Last Name"
                className="validate"
                required
              />
              <label htmlFor="last_name">Last Name</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Patient Email"
                className="validate"
                required
              />
              <label htmlFor="email">Email</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <input
                id="date_of_birth"
                name="date_of_birth"
                type="date"
                placeholder="Patient Date of Birth"
                className="validate"
                required
              />
              <label htmlFor="date_of_birth">Date of Birth</label>
            </div>
          </div>

          <button type="submit" className="btn black mt2 mr2 mb2">Add</button>
          <button type="button" onClick={onCancelClick} className="btn white black-text mt2 mb2">Cancel</button>
        </form>
      </div>
    </div>
  );
};

export default InvitePatient;
