const STATUSES = {
  na: 'N/A',
  proof_of_concept: 'Proof of Concept',
  free_trial: 'Free Trial',
  customer: 'Customer',
  frozen: 'Frozen',
};

// Used array to save the order of the values
const TRIAL_DURATIONS = [
  ['', ''],
  [30, '30 days'],
  [60, '60 days'],
  [90, '90 days'],
];

const SALES_REPS = [
  ['', ''],
  ['Aaron', 'Aaron'],
  ['Fred', 'Fred'],
  ['Dani', 'Dani'],
  ['Sam', 'Sam'],
  ['other', 'Other / Test'],
];

export { STATUSES, TRIAL_DURATIONS, SALES_REPS };
